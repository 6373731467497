import classNames from 'classnames';
import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { setSidebarNotificationsOpen } from '../../redux/sidebar/actions';
import { useDispatch } from '../../redux/store';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { useNotifications } from './NotificationsContext';
import { NotificationsFeedItem } from './utils';

interface NotificationsCardProps {
  item: NotificationsFeedItem;
}

export const NotificationsCard = ({
  item,
}: NotificationsCardProps): JSX.Element => {
  const dispatch = useDispatch();
  const { markNotificationAsRead } = useNotifications();
  const closeNotificationsSidebar = useCallback(() => {
    dispatch(setSidebarNotificationsOpen(false));
  }, [dispatch]);

  const commonAvatarClassNames = 'rounded-[50%] w-8 h-8 mr-2';

  return (
    <NavLink
      className="text-xs px-4 py-4 text-inherit relative flex align-top border-b border-cloud-20 hover:bg-cloud-15 transition-all cursor-pointer"
      key={item.id}
      onClick={() => {
        if (!item.read) {
          markNotificationAsRead(item.knockItem);
        }

        closeNotificationsSidebar();
      }}
      to={`/topic/${item.topic_id}`}
    >
      {item.sender.avatar ? (
        <img
          alt={item.sender.name}
          className={classNames(commonAvatarClassNames, 'object-cover')}
          src={item.sender.avatar}
        />
      ) : (
        <div
          className={classNames(
            commonAvatarClassNames,
            'defaultAvatar border-1 border-solid border-cloud-40 flex items-center'
          )}
        >
          <UIIcon name="person" size={32} />
        </div>
      )}

      <div>
        <div>
          {item.markdown && (
            <div
              className="notificationMarkdown"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: item.markdown,
              }}
            />
          )}
          <div className="text-xs text-gray-50">{item.displayableTime}</div>
        </div>
        {!item.read && (
          <div className="absolute top-2 left-2 w-2 h-2 rounded-[50%] bg-destructive-40" />
        )}
      </div>
    </NavLink>
  );
};
