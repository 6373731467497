import { makeLazyComponent } from '../../../scripts/utils';

export const LazyServiceAccountPage = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "service-account" */ './ServiceAccountPage'
      )
    ).ServiceAccountPage
);
