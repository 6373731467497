import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ctorMap } from '../../../apps';
import { AppName, useAppDefinitions } from '../../../apps/definition';
import { errorAppStates } from '../../../constants';
import { trackEvent } from '../../../extra/sharedMethods';
import {
  integrationInstallMessages,
  integrationUninstallMessages,
} from '../../../messages';
import { useAppConnectionStatus } from '../../../pages/onboarding/WorkspaceSetupPage/hooks/app-connection-summary';
import { useDispatch } from '../../../redux/store';
import { useQAController } from '../../../scripts/QAController';
import { updateUserReconnectedApps } from '../../../scripts/apis';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { useFlag, useToaster, useUserSafe } from '../../../scripts/hooks';
import { useAppName } from '../../../scripts/hooks/apps';
import {
  ManualFetchDataType,
  useManualDataFetch,
} from '../../../scripts/hooks/manual-trigger-hooks';
import { logError } from '../../../scripts/utils';
import {
  AppInstallController,
  AppInstallControllerProvider,
  AppInstallEventArgs,
  AppInstallRequest,
} from '../../admin/AppsTable/AppInstallController';
import {
  OrgAdminButton,
  PersonalButton,
} from '../../admin/AppsTableRow/Buttons/ConnectionButtons';
import { ConnectionInfoPopover } from '../../admin/AppsTableRow/ConnectionInfoPopover';
import {
  ConfirmDialog,
  ConfirmMode,
} from '../../controls/Dialog/ConfirmDialog';
import { LinkFavicon } from '../../controls/LinkFavicon/LinkFavicon';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UIIconButton } from '../../controls/ui/UIIconButton/UIIconButton';
import { useBound } from '../../pageSearch/results/misc/hooks';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../shadcn/lib/components/card';

// eslint-disable-next-line max-lines-per-function
export const ReconnectAppsModal: FC = () => {
  const [open, setOpen] = useState(true);
  const [showChild, setShowChild] = useState(true);

  const user = useUserSafe();
  const appInstallController = useMemo(() => new AppInstallController(), []);
  const toaster = useToaster();

  const dashAiReconnectAppsEnabled = useFlag('dashAiReconnectAppsEnabled');

  const { getAppName } = useAppName();
  const connectedApps = useAppConnectionStatus();
  const definitions = useAppDefinitions();

  const qaController = useQAController();

  const connections = useMemo(() => {
    return [...user.orgByOrgId.usersAppsByOrg.nodes, ...user.usersApps.nodes];
  }, [user.orgByOrgId.usersAppsByOrg.nodes, user.usersApps.nodes]);

  const appsInErrorState = useMemo(() => {
    const reconnectApps = connectedApps.filter((app) => {
      const appConnection = connections.find(
        (connection) => connection.id === app.id
      );

      // TODO(DAS-21985): Remove this condition (shows org apps to only the admin who connected that app)
      const isAppConnectedBySameUser =
        appConnection && appConnection.userId === user.userId;

      if (user.admin) {
        return (
          errorAppStates.has(app.statusCode) &&
          app.isInstant &&
          isAppConnectedBySameUser
        );
      }

      return (
        errorAppStates.has(app.statusCode) &&
        app.isInstant &&
        isAppConnectedBySameUser &&
        !app.isOrg
      );
    });

    const isReconnectAppWithLessThanTwoUserReconnectsPresent =
      reconnectApps.some(
        ({ shortName }) =>
          !(shortName in user.reconnectedApps) ||
          user.reconnectedApps[shortName] < 2
      );

    return isReconnectAppWithLessThanTwoUserReconnectsPresent
      ? reconnectApps
      : [];
  }, [
    user.admin,
    user.userId,
    user.reconnectedApps,
    connectedApps,
    connections,
  ]);

  const showModal = useMemo(
    () =>
      dashAiReconnectAppsEnabled &&
      showChild &&
      appsInErrorState.length > 0 &&
      open,
    [dashAiReconnectAppsEnabled, showChild, appsInErrorState, open]
  );

  const [currentAppInstallRequest, setCurrentAppInstallRequest] = useState<
    AppInstallRequest | undefined
  >();

  const [currentAppUninstallRequest, setCurrentAppUninstallRequest] = useState<
    AppInstallRequest | undefined
  >();

  const unsetInstallApp = useBound(setCurrentAppInstallRequest, undefined);
  const unsetUninstallApp = useBound(setCurrentAppUninstallRequest, undefined);
  const dispatch = useDispatch();
  const manualTriggerAppRefresh = useManualDataFetch(
    dispatch,
    ManualFetchDataType.APPS
  );

  const manualTriggerUserRefresh = useManualDataFetch(
    dispatch,
    ManualFetchDataType.USER
  );

  const InstallDialog = currentAppInstallRequest?.app.getInstallDialog(
    currentAppInstallRequest.isOrg
  );

  const showUninstallModal = useCallback(
    ({ appUninstallRequest }: Partial<AppInstallEventArgs>) => {
      setCurrentAppUninstallRequest(appUninstallRequest);
    },
    [setCurrentAppUninstallRequest]
  );

  const handleInstall = useCallback(
    ({ appInstallRequest }: Partial<AppInstallEventArgs>) => {
      if (!appInstallRequest) {
        return;
      }

      setCurrentAppInstallRequest(appInstallRequest);

      if (!appInstallRequest.app.getInstallDialog(appInstallRequest.isOrg)) {
        // there is no dialog for this app, install it now
        toaster
          .withPromise(
            appInstallRequest.app
              .install(appInstallRequest.isOrg)
              .then(manualTriggerAppRefresh),
            ...integrationInstallMessages(appInstallRequest.app.definition),
            6000
          )
          .catch(logError);
      }
    },
    [setCurrentAppInstallRequest, toaster, manualTriggerAppRefresh]
  );

  const handleUninstall = useCallback(() => {
    if (currentAppUninstallRequest?.app) {
      toaster
        .withPromise(
          currentAppUninstallRequest.app
            .uninstall(currentAppUninstallRequest.isOrg)
            .then(manualTriggerAppRefresh),
          ...integrationUninstallMessages(
            currentAppUninstallRequest.app.definition.displayName
          ),
          6000
        )
        .catch(logError);

      setCurrentAppUninstallRequest(undefined);
    }
  }, [currentAppUninstallRequest, manualTriggerAppRefresh, toaster]);

  const handleHideAppsNotifications = useCallback(() => {
    setShowChild(false);
  }, []);

  const updateReconnectedApps = useCallback(
    (data: Record<AppName, number>) => {
      updateUserReconnectedApps(user.userId, data)
        .then(manualTriggerUserRefresh)
        .catch((error) => {
          logError(error);
        });
    },
    [user.userId, manualTriggerUserRefresh]
  );

  useEffect(() => {
    const event_listener_handle_install = (
      eventArgs: Partial<AppInstallEventArgs>
    ) => {
      handleInstall(eventArgs);
    };

    const event_listener_handle_uninstall = (
      eventArgs: Partial<AppInstallEventArgs>
    ) => {
      showUninstallModal(eventArgs);
    };

    appInstallController.on(
      'handle_app_install',
      event_listener_handle_install
    );

    appInstallController.on(
      'handle_app_uninstall',
      event_listener_handle_uninstall
    );

    return () => {
      appInstallController.off(
        'handle_app_install',
        event_listener_handle_install
      );

      appInstallController.off(
        'handle_app_uninstall',
        event_listener_handle_uninstall
      );
    };
  }, [appInstallController, handleInstall, showUninstallModal]);

  useEffect(() => {
    qaController.listenEvent(
      'hideAppsNotifications',
      handleHideAppsNotifications
    );

    return () => {
      qaController.off('hideAppsNotifications', handleHideAppsNotifications);
    };
  }, [qaController, handleHideAppsNotifications]);

  useEffect(() => {
    if (showModal) {
      trackEvent(AnalyticsEvent.ReconnectAppsModalShown, { surface: 'dashai' });
    }
  }, [showModal]);

  return showModal ? (
    <>
      <AppInstallControllerProvider value={appInstallController}>
        <Card className="sm:block hidden max-h-64 fixed top-14 right-8 z-20 overflow-auto animate-in fade-in ease-in-out duration-1000">
          <CardHeader className="flex flex-row justify-between">
            <CardTitle className="text-lg">
              Reconnect Apps for accurate answers
            </CardTitle>
            <UIIconButton
              name="cross"
              onClick={() => {
                setOpen(false);

                const data = Object.fromEntries(
                  appsInErrorState.map(({ shortName }) => [shortName, 1])
                ) as Record<AppName, number>;

                updateReconnectedApps(data);
              }}
            />
          </CardHeader>
          <CardContent className="w-full flex items-start gap-2 flex-col flex-wrap flex-none order-none self-stretch grow-0">
            {appsInErrorState.map((app) => {
              const definition = definitions[app.shortName];
              const AppConstructor = ctorMap.get(app.shortName);

              let appCtor;
              if (definition && AppConstructor) {
                appCtor = new AppConstructor(definition);
              }

              const appConnection = connections.find(
                (connection) => connection.id === app.id
              );

              const appName =
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                app.connectionName || getAppName(app.id) || app.displayName;

              return (
                definition &&
                appCtor &&
                appConnection && (
                  <div
                    className="flex items-center justify-between gap-8 p-2 flex-none order-none self-stretch grow rounded bg-cloud-10"
                    key={app.id}
                  >
                    <div className="flex items-center gap-2">
                      {app.appName === 'website' ? (
                        <LinkFavicon
                          link={`http://${app.workspaceName!}`}
                          size={24}
                        />
                      ) : (
                        definition.icon ?? (
                          <UIIcon name={app.shortName} size={24} type="apps" />
                        )
                      )}
                      <span>
                        {appName.length > 20
                          ? `${appName.slice(0, 20)}...`
                          : appName}
                      </span>
                      <ConnectionInfoPopover
                        app={appCtor}
                        connection={appConnection}
                      />
                    </div>
                    {appConnection.org ? (
                      <OrgAdminButton
                        app={appCtor}
                        connection={appConnection}
                        installCallback={() => {
                          updateReconnectedApps({
                            [app.shortName]: 1,
                          } as Record<AppName, number>);
                        }}
                        installEventName={
                          AnalyticsEvent.ReconnectAppsModalReconnectClicked
                        }
                      />
                    ) : (
                      <PersonalButton
                        app={appCtor}
                        connection={appConnection}
                        installCallback={() => {
                          updateReconnectedApps({
                            [app.shortName]: 1,
                          } as Record<AppName, number>);
                        }}
                        installEventName={
                          AnalyticsEvent.ReconnectAppsModalReconnectClicked
                        }
                      />
                    )}
                  </div>
                )
              );
            })}
          </CardContent>
        </Card>
      </AppInstallControllerProvider>

      {InstallDialog && (
        <InstallDialog
          app={currentAppInstallRequest!.app}
          isOrg={currentAppInstallRequest!.isOrg}
          onClose={unsetInstallApp}
          open={!!currentAppInstallRequest}
        />
      )}
      {currentAppUninstallRequest && (
        <ConfirmDialog
          confirmButtonText="Disconnect"
          confirmButtonType="delete"
          mode={ConfirmMode.Delete}
          onCancel={unsetUninstallApp}
          onConfirm={handleUninstall}
          subject="app"
          title={`Disconnect ${currentAppUninstallRequest.app.definition.displayName}`}
          verb="disconnect"
        />
      )}
    </>
  ) : null;
};
