import React, { FC } from 'react';
import { AuthorDetails } from '../../../components/controls/AuthorDetails/AuthorDetails';
import { DateFormat, Timestamp } from '../../../components/controls/Timestamp';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/shadcn/lib/components/table';
import { Bot } from '../../../models/Bots';

interface BotsTabProps {
  bots: Bot[];
  setCurrentBot: (bot: Bot) => void;
}

export const BotsTab: FC<BotsTabProps> = ({ bots, setCurrentBot }) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-1/3">Name</TableHead>
          <TableHead className="w-1/3">Created by</TableHead>
          <TableHead className="w-1/3">Created on</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {bots.map((bot) => (
          <TableRow
            className={bot.is_default ? '' : 'cursor-pointer'}
            key={bot.id}
            onClick={() => {
              if (bot.is_default) {
                return;
              }

              setCurrentBot(bot);
            }}
          >
            <TableCell>
              <div className="flex items-center gap-2">
                <div className="text-2xl">{bot.icon}</div>
                <div>{bot.bot_name}</div>
              </div>
            </TableCell>
            <TableCell>
              <AuthorDetails
                displayName={bot.author.display_name ?? bot.author.email}
                icon={bot.author.icon}
              />
            </TableCell>
            <TableCell>
              <Timestamp
                className="text-sm text-cloud-40"
                format={DateFormat.DateOnly}
                unixTime={bot.created_at}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
