import React from 'react';
import { createPortal } from 'react-dom';
import { NotificationsFeed } from './NotificationsFeed';
import './Notifications.scss';

export const NotificationsSidebar = (): JSX.Element => {
  return createPortal(
    <div className="notificationSidebar fixed top-0 bottom-0 bg-cloud-5 sm:left-[var(--sidebar-width)] sm:z-[139] left-0 z-[141] overflow-y-auto custom-scrollbar sm:w-[400px] w-auto right-0">
      <NotificationsFeed />
    </div>,
    document.body
  );
};
