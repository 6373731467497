import classNames from 'classnames';
import React, { forwardRef } from 'react';
import InlineSVG, { Props } from 'react-inlinesvg';
import './UIIcon.scss';
import { AppName } from '../../../../apps/definition';
import { UITooltip } from '../UIToolTip/UIToolTip';

// required for testing
// eslint-disable-next-line unicorn/prefer-module, @typescript-eslint/no-unnecessary-condition
require.context('../../../../assets/icons', true, /\.svg$/);

export type UIIcons =
  | 'activity'
  | 'add-circle'
  | 'add-comment'
  | 'add-to-collection'
  | 'ai-sparkle'
  | 'answer'
  | 'app'
  | 'archive'
  | 'arrow-down-left-long'
  | 'arrow-down-long'
  | 'arrow-down'
  | 'arrow-left-long'
  | 'arrow-left'
  | 'arrow-right-long'
  | 'arrow-right'
  | 'arrow-up-long'
  | 'arrow-up-right'
  | 'arrow-up'
  | 'at-sign'
  | 'attachment'
  | 'bell'
  | 'bolt'
  | 'bookmark'
  | 'bot'
  | 'calendar'
  | 'card'
  | 'chat'
  | 'check-circle-filled'
  | 'check-circle-solid'
  | 'check-circle'
  | 'check'
  | 'cloud'
  | 'cog'
  | 'collection'
  | 'comment'
  | 'company'
  | 'cone'
  | 'connect'
  | 'copy'
  | 'cross'
  | 'design'
  | 'doc'
  | 'download'
  | 'editor-bold'
  | 'editor-bullet'
  | 'editor-italic'
  | 'editor-list'
  | 'editor-quote'
  | 'editor-strike'
  | 'electric-bolt-with-plus'
  | 'enter'
  | 'error-solid'
  | 'error'
  | 'event'
  | 'exclamation'
  | 'extension'
  | 'external'
  | 'eye'
  | 'file-other'
  | 'file'
  | 'files'
  | 'filter'
  | 'flag'
  | 'folder'
  | 'forum'
  | 'gift'
  | 'globe'
  | 'glossary'
  | 'grapple'
  | 'group'
  | 'happy-face'
  | 'help-1'
  | 'help'
  | 'home'
  | 'image'
  | 'info'
  | 'invite'
  | 'item'
  | 'key'
  | 'keyboard-down'
  | 'keyboard-left'
  | 'keyboard-right'
  | 'keyboard-up'
  | 'left-right'
  | 'light-bulb'
  | 'link-copy'
  | 'link'
  | 'location'
  | 'lock'
  | 'log-out'
  | 'mail'
  | 'megaphone'
  | 'menu'
  | 'message'
  | 'messages'
  | 'more-vertical'
  | 'more'
  | 'my-app'
  | 'note'
  | 'org-chart'
  | 'pdf'
  | 'pencil-2'
  | 'pencil'
  | 'people'
  | 'person'
  | 'pin-filled'
  | 'pin'
  | 'plus-circle-filled'
  | 'plus'
  | 'project'
  | 'question'
  | 'sad-face'
  | 'search-2'
  | 'search'
  | 'send'
  | 'settings-2'
  | 'settings'
  | 'share'
  | 'sheet'
  | 'shield-question'
  | 'slide-mango'
  | 'slide'
  | 'sorting'
  | 'spinner-animated'
  | 'star'
  | 'stop-circle'
  | 'sync'
  | 'task'
  | 'thumb-down'
  | 'thumb-up'
  | 'time'
  | 'trash-can'
  | 'trending'
  | 'unassigned'
  | 'undo'
  | 'up-down'
  | 'update'
  | 'visibility-off';

export type AppIcons =
  | AppName
  | 'anthropic'
  | 'azure'
  | 'chrome'
  | 'dashworks-white'
  | 'dashworks'
  | 'dropbox-paper'
  | 'firefox'
  | 'google'
  | 'linkedin'
  | 'meta'
  | 'mistral'
  | 'okta'
  | 'openai';

export type FileIcons = 'adobe-pdf' | 'presentation' | 'spreadsheet' | 'word';

export interface BaseIconProps {
  size?: number;
  stroke?: boolean;

  // Custom classes
  className?: string;

  // Optional tooltip
  tooltip?: React.ReactNode;
  square?: boolean;

  style?: React.CSSProperties;
  tabIndex?: number;

  role?: string;

  onClick?(ev: React.SyntheticEvent): void;
  onKeyDown?(ev: React.KeyboardEvent): void;
}

interface AppsIconProps extends BaseIconProps {
  name: AppIcons;
  type: 'apps';
}

interface UIIconProps extends BaseIconProps {
  type?: 'ui';
  name: UIIcons;
}

interface FileIconProps extends BaseIconProps {
  type: 'files';
  name: FileIcons;
}

export type IconProps = AppsIconProps | FileIconProps | UIIconProps;

const fetchOpts: RequestInit = {
  headers: {
    accept: 'image/svg',
  },
};

const InnerRefForward = forwardRef<SVGElement, Props>((props, ref) => {
  return <InlineSVG {...props} fetchOptions={fetchOpts} innerRef={ref} />;
});

InnerRefForward.displayName = 'InnerRefForward';

/**
 * From https://dev.to/barrbozzo/the-right-way-to-use-svg-icons-with-react-30o3
 */
export const UIIcon = forwardRef<SVGElement, IconProps>(
  (
    {
      className,
      name,
      size,
      type = 'ui',
      tooltip,
      square,
      stroke,
      ...remainder
    },
    ref
  ) => {
    let width = 0;
    width = type === 'ui' ? size ?? 16 : size ?? 20;

    const svgImage = (
      <InnerRefForward
        {...remainder}
        className={classNames(className, 'svgIcon', {
          square,
          ui: type === 'ui',
          app: type === 'apps',
          stroke,
        })}
        height={width}
        ref={ref}
        src={`/assets/images/icons/${type}/${name.toLowerCase()}.svg`}
        width={width}
      />
    );

    return tooltip ? (
      <UITooltip title={tooltip}>{svgImage}</UITooltip>
    ) : (
      svgImage
    );
  }
);

UIIcon.displayName = 'UIIcon';
