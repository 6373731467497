import Popover from '@mui/material/Popover';
import classNames from 'classnames';
import React, { FC, RefObject, useCallback, useMemo, useState } from 'react';
import { FINCH_APPS } from '../../../apps/definition';
import { Reference, UserInfo } from '../../../models/QAmodels';
import { Timestamp } from '../../controls/Timestamp';
import { UserImage } from '../../controls/UserImage/UserImage';
import { ReferenceIcon } from './QAReferenceUtil';

interface IReferencePopover {
  reference: Reference;
  handlePopoverOpen: () => void;
  handlePopoverClose: () => void;
  openedPopover: boolean;
  popoverAnchor: RefObject<HTMLElement>;
}

export const ReferencePopover: FC<IReferencePopover> = ({
  reference,
  handlePopoverClose,
  handlePopoverOpen,
  openedPopover,
  popoverAnchor,
}) => {
  const [popoverPosition, setPopoverPosition] = useState('bottom');
  const {
    author,
    extra_fields,
    modified_time,
    object_type,
    snippet,
    source,
    url,
    title: referenceTitle,
  } = reference;

  const title = useMemo(
    () => referenceTitle || (url ?? ''),
    [referenceTitle, url]
  );

  const checkAnchorPosition = useCallback(() => {
    if (popoverAnchor.current) {
      const anchorBounds = popoverAnchor.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      // Check if the anchor is too close to the bottom of the viewport
      if (viewportHeight - anchorBounds.bottom < 300) {
        setPopoverPosition('top');
      } else {
        setPopoverPosition('bottom');
      }
    }
  }, [popoverAnchor]);

  const handlePopoverOpenWrapper = useCallback(() => {
    checkAnchorPosition();
    handlePopoverOpen();
  }, [checkAnchorPosition, handlePopoverOpen]);

  return (
    <Popover
      PaperProps={{
        onMouseEnter: handlePopoverOpenWrapper,
        onMouseLeave: handlePopoverClose,
      }}
      anchorEl={popoverAnchor.current}
      anchorOrigin={{
        vertical: popoverPosition === 'top' ? 'top' : 'bottom',
        horizontal: 'left',
      }}
      classes={{
        paper: 'paper',
      }}
      disableRestoreFocus
      id="mouse-over-popover"
      onClose={handlePopoverClose}
      open={openedPopover}
      sx={{
        marginTop: popoverPosition === 'top' ? '-4px' : '4px',
        pointerEvents: 'none',
        '.MuiPaper-root': {
          boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
        },
      }}
      transformOrigin={{
        vertical: popoverPosition === 'top' ? 'bottom' : 'top',
        horizontal: 'left',
      }}
    >
      <div className="referencePreview">
        <div className="header">
          <div className="title">{title}</div>
          <div>
            <ReferenceIcon source={source} url={url} />
          </div>
        </div>
        {!FINCH_APPS.has(source) && snippet && (
          <div className="border-b py-3 px-4 flex flex-col max-h-36 overflow-y-auto">
            <div className="text-cloud-40 text-sm">AI Summary</div>
            <div className="break-words">{snippet}</div>
          </div>
        )}
        <div className="extraFields">
          {author && (
            <div className="fieldContainer">
              <div className="fieldName">Author</div>
              <UserIcon
                className="fieldValue"
                email={author.email}
                name={author.name}
                photo={author.photo}
              />
            </div>
          )}
          {object_type && object_type !== '' && (
            <div className="fieldContainer">
              <div className="fieldName">Type</div>
              <div className="capitalize fieldValue">{object_type}</div>
            </div>
          )}
          {modified_time !== undefined && modified_time > 0 && (
            <div className="fieldContainer">
              <div className="fieldName">Last Modified</div>
              <Timestamp className="fieldValue" unixTime={modified_time} />
            </div>
          )}
          {extra_fields &&
            Object.entries(extra_fields).map(([key, value]) => (
              <div className="fieldContainer" key={key}>
                <div className="fieldName">{key}</div>
                <div className="fieldValue">{value}</div>
              </div>
            ))}
        </div>
      </div>
    </Popover>
  );
};

const UserIcon: FC<UserInfo> = ({ className, email, name, photo }) => (
  <div className={classNames('flex gap-2', className)}>
    {photo && <UserImage imageUrl={photo} size={20} />}
    <div>{name ?? email}</div>
  </div>
);
