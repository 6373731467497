import { AllowedDomain, OrgByOrg, User, UserApp } from '../models/User';
import { AllSearchRelatedParams } from '../scripts/hooks';
import { uniqBy } from '../scripts/utils';
import { Action, RootActions } from './actions';
import { reducer as debugReducer } from './debug/reducer';
import { metaReducer } from './meta/reducers';
import { pageSearchReducer } from './pageSearch/reducer';
import { reducer as sidebarReducer } from './sidebar/reducer';
import { DEFAULT_STATE, GlobalState, Tokens } from './state';
import { toastReducer } from './toast/reducer';
import { workflowReducer } from './workflow/workflowReducer';

function appReducer(state: GlobalState, action: Action): GlobalState {
  switch (action.type) {
    case RootActions.SetSearchHistory: {
      const searchHistory = uniqBy(
        action.payload as AllSearchRelatedParams[],
        'q'
      );

      window.localStorage.setItem(
        'searchHistory',
        JSON.stringify(searchHistory)
      );

      return {
        ...state,
        searchHistory,
      };
    }

    case RootActions.SetToken: {
      return {
        ...state,
        tokens: action.payload as Tokens | undefined,
      };
    }

    case RootActions.SetUser: {
      const user = action.payload as User;
      return {
        ...state,
        user,
      };
    }

    case RootActions.SetPartialUser: {
      const partialUser = action.payload as Partial<User>;
      if (!state.user) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          ...partialUser,
        },
      };
    }

    case RootActions.SetPartialOrg: {
      const partialOrg = action.payload as Partial<OrgByOrg>;
      if (!state.user) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          orgByOrgId: {
            ...state.user.orgByOrgId,
            ...partialOrg,
          },
        },
      };
    }

    case RootActions.SetPartialApps: {
      const { userApps, orgApps } = action.payload as {
        userApps: UserApp[];
        orgApps: UserApp[];
      };

      if (!state.user) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          usersApps: {
            nodes: userApps,
          },
          orgByOrgId: {
            ...state.user.orgByOrgId,
            usersAppsByOrg: {
              nodes: orgApps,
            },
          },
        },
      };
    }

    case RootActions.SetAllowedDomains: {
      const allowedDomains = action.payload as AllowedDomain[];

      if (!state.user) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          orgByOrgId: {
            ...state.user.orgByOrgId,
            allowedDomains: {
              nodes: allowedDomains,
            },
          },
        },
      };
    }

    case RootActions.SetRecommendedQueries: {
      const recommendedQueries = action.payload as [string];
      return {
        ...state,
        recommendedQueries,
      };
    }

    case RootActions.SetUserIsAdminInternal: {
      const admin = action.payload as boolean;
      if (!state.user) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          admin,
        },
      };
    }

    case RootActions.FeatureFlagsFullyLoaded: {
      return {
        ...state,
        featureFlagsFullyLoaded: action.payload as boolean,
      };
    }
  }

  return state;
}

export const rootReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: GlobalState = DEFAULT_STATE,
  action: Action
): GlobalState => {
  // Delegate to specific area reducers
  if (action.type.startsWith('PAGE_SEARCH_')) {
    return pageSearchReducer(state, action);
  }

  if (action.type.startsWith('ADMIN_')) {
    return sidebarReducer(state, action);
  }

  if (action.type.startsWith('META_')) {
    return metaReducer(state, action);
  }

  if (action.type.startsWith('TOAST_')) {
    return toastReducer(state, action);
  }

  if (action.type.startsWith('WORKFLOW_')) {
    return workflowReducer(state, action);
  }

  if (action.type.startsWith('DEBUG_')) {
    return debugReducer(state, action);
  }

  return appReducer(state, action);
};
