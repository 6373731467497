import React, { FC, useState } from 'react';
import { UserApp } from '../../../models/User';
import { App } from '../../../scripts/app';
import { LinkFavicon } from '../../controls/LinkFavicon/LinkFavicon';
import { Timestamp } from '../../controls/Timestamp';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../shadcn/lib/components/popover';

export const ConnectionInfoPopover: FC<{
  app: App;
  connection: UserApp;
}> = ({ app, connection }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <Popover onOpenChange={setPopoverOpen} open={popoverOpen}>
      <PopoverTrigger asChild className="hidden sm:block">
        <div
          className="flex"
          onMouseEnter={() => {
            setPopoverOpen(true);
          }}
          onMouseLeave={() => {
            setPopoverOpen(false);
          }}
        >
          <UIIcon name="info" />
        </div>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col w-80 z-[99999]" side="right">
        <div className="flex justify-between">
          <div className="font-medium">{app.definition.displayName}</div>
          <div>
            {connection.appName === 'website' ? (
              <LinkFavicon
                link={`http://${connection.workspaceName!}`}
                size={20}
              />
            ) : (
              <UIIcon name={connection.appName} type="apps" />
            )}
          </div>
        </div>
        <div className="flex justify-between mt-2 gap-2">
          <div className="text-cloud-40">Connection</div>
          <div className="break-all">
            {connection.org ? 'Org' : 'Individual'}
          </div>
        </div>
        {connection.workspaceName && (
          <div className="flex justify-between mt-2 gap-2">
            <div className="text-cloud-40">Workspace</div>
            <div className="break-all">{connection.workspaceName}</div>
          </div>
        )}
        {connection.email && (
          <div className="flex justify-between mt-2 gap-2">
            <div className="text-cloud-40">Account</div>
            <div>{connection.email}</div>
          </div>
        )}
        {connection.createdAt && (
          <div className="flex justify-between mt-2 gap-2">
            <div className="text-cloud-40">Created</div>
            <Timestamp
              unixTime={Math.floor(
                new Date(connection.createdAt).getTime() / 1000
              )}
            />
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};
