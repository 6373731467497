/* eslint-disable @cspell/spellchecker */
import './QAFeedbackModal.scss';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { trackEvent } from '../../extra/sharedMethods';
import { QAMessage, Reference } from '../../models/QAmodels';
import {
  QAControllerEventArgs,
  useQAController,
} from '../../scripts/QAController';
import {
  FeedbackRequestBody,
  sendFeedback,
} from '../../scripts/apis/qaFeedback';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useBoolState, useToaster } from '../../scripts/hooks';
import { logError } from '../../scripts/utils';
import { Dialog } from '../controls/Dialog/Dialog';
import { UIButton } from '../controls/ui/UIButton/UIButton';
import { UIIconButton } from '../controls/ui/UIIconButton/UIIconButton';
import { UITextArea } from '../controls/ui/UITextArea/UITextArea';
import { ReferenceIcon } from './references/QAReferenceUtil';

interface IQAFeedbackModal {
  sharableConversation?: QAMessage[];
}

// eslint-disable-next-line max-lines-per-function
export const QAFeedbackModal: FC<IQAFeedbackModal> = ({
  sharableConversation,
}) => {
  const [open, setOpen, setNotOpen] = useBoolState(false);
  const [submitting, setSubmitting, setNotSubmitting] = useBoolState(false);
  const [answerId, setAnswerId] = useState('');
  const [answerRating, setAnswerRating] = useState(0);
  const [feedbackComment, setFeedbackComment] = useState('');
  const [references, setReferences] = useState<Reference[]>([]);
  const [referencesFeedback, setReferencesFeedback] = useState<
    Record<string, number>
  >({});

  const toaster = useToaster();
  const qaController = useQAController();
  const parentMessage = qaController.getUserMessageForAssistantAnswer(
    answerId,
    sharableConversation
  );

  const handleAnswer = useCallback(() => {
    qaController.triggerEvent('showAnswerDialog', {
      answerCreateModalTitle: 'Help improve the response for your team',
      answerCreatePrefillQuestion: parentMessage?.messageText,
    });
  }, [qaController, parentMessage]);

  const handleSubmit = useCallback(
    (showBlocking: boolean) => {
      const referencesFeedbackFinal: Record<string, number> = {};
      for (const [reference, feedback] of Object.entries(referencesFeedback)) {
        const ref = JSON.parse(reference) as Reference;
        if (ref.id) {
          referencesFeedbackFinal[ref.id] = feedback;
        }
      }

      const feedbackBody: FeedbackRequestBody = {
        query_id: parentMessage!.row_id.replace(/-/g, ''),
        response_id: answerId.replace(/-/g, ''),
        rating: answerRating,
        comment: feedbackComment,
        references_feedback: referencesFeedbackFinal,
      };

      trackEvent(
        AnalyticsEvent.QAMessageFeedback,
        {},
        {
          ...feedbackBody,
          references_feedback: JSON.stringify(referencesFeedbackFinal),
        }
      );

      if (showBlocking) {
        setSubmitting();
      } else {
        setNotOpen();
      }

      sendFeedback(feedbackBody)
        .then(() => {
          toaster.success(
            "We've saved your feedback to improve Dash AI for your team."
          );

          setNotSubmitting();
          setNotOpen();
          setFeedbackComment('');
          setReferencesFeedback({});
        })
        .catch((error) => {
          toaster.failure(
            'There was an error sending feedback. Please try again.'
          );

          setNotSubmitting();
          logError(error);
        });
    },
    [
      answerId,
      parentMessage,
      answerRating,
      feedbackComment,
      referencesFeedback,
      setSubmitting,
      setNotSubmitting,
      setNotOpen,
      toaster,
    ]
  );

  const handleShowFeedbackModal = useCallback(
    ({
      response_id,
      response_references,
      response_rating,
    }: Partial<QAControllerEventArgs>) => {
      setAnswerId(response_id!);
      setAnswerRating(response_rating!);
      setReferences(response_references!);
      setOpen();
    },
    [setOpen]
  );

  useEffect(() => {
    qaController.listenEvent('showFeedbackModal', handleShowFeedbackModal);
    return () => {
      qaController.off('showFeedbackModal', handleShowFeedbackModal);
    };
  }, [qaController, handleShowFeedbackModal]);

  return (
    <Dialog
      content={
        <div>
          <div className="flex justify-end">
            <UIIconButton
              name="cross"
              onClick={() => {
                handleSubmit(false);
              }}
              size={24}
            />
          </div>
          <h4 className="m-0 mt-4 text-xl font-semibold">
            {answerRating === 1
              ? "Anything else you'd like to share about this answer?"
              : 'What went wrong with this answer?'}
          </h4>
          <div className="mt-6">
            <div className="text-sm text-cloud-40">
              {answerRating === 1
                ? 'Share anything else about this answer (optional)'
                : 'Share feedback, helpful links or anything else about this answer'}
            </div>
            <UITextArea
              autoFocus
              className="mt-2"
              defaultWhiteInputField
              onChange={setFeedbackComment}
              placeholder="Enter details"
              value={feedbackComment}
            />
          </div>
          {references.length > 0 && (
            <div className="mt-6">
              <div className="text-sm text-cloud-40">
                Provide feedback on the references used in this answer{' '}
                {answerRating === 1 && '(optional)'}
              </div>
              <div className="mt-2 max-h-[392px] overflow-scroll references">
                {references.map((reference) => {
                  const url = reference.url ?? '';
                  const title = reference.title || url;
                  const { id, source } = reference;

                  const onReferenceClick = (
                    e: React.MouseEvent<HTMLDivElement>
                  ) => {
                    if (source === 'verified_answers') {
                      // verified answer id is expected to be last fragment in url
                      const verifiedAnswerId = url.split('/').pop();
                      qaController.triggerEvent('showAnswerDialog', {
                        answerId: verifiedAnswerId,
                      });

                      e.preventDefault();
                      return;
                    }

                    if (url) {
                      window.open(url);
                    }
                  };

                  const onFeedbackAction = (rating: number) => {
                    trackEvent(
                      AnalyticsEvent.QAMessageReferenceRate,
                      {
                        reference_id: id,
                        answer_id: answerId,
                        reference_rating: rating,
                        answer_rating: answerRating,
                      },
                      {
                        reference_url: url,
                        reference_source: source,
                      }
                    );

                    if (
                      referencesFeedback[JSON.stringify(reference)] === rating
                    ) {
                      setReferencesFeedback((_referencesFeedback) => {
                        const {
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          [JSON.stringify(reference)]: _,
                          ...updatedReferencesFeedback
                        } = _referencesFeedback;

                        return updatedReferencesFeedback;
                      });
                    } else {
                      setReferencesFeedback((_referencesFeedback) => ({
                        ..._referencesFeedback,
                        [JSON.stringify(reference)]: rating,
                      }));
                    }
                  };

                  return (
                    <div
                      className="flex justify-between gap-4 p-2 border-b"
                      key={`reference_${id}`}
                    >
                      <div className="flex gap-2 items-center">
                        <div>
                          <ReferenceIcon size={16} source={source} url={url} />
                        </div>
                        <div
                          className="cursor-pointer hover:underline"
                          onClick={onReferenceClick}
                        >
                          {title.length > 50
                            ? `${title.slice(0, 50)}...`
                            : title}
                        </div>
                      </div>
                      <div className="flex gap-1 items-center">
                        <div className="inline-flex hover:bg-cloud-15 p-1 rounded">
                          <UIIconButton
                            className="focus:outline-none"
                            name="thumb-up"
                            onClick={() => {
                              onFeedbackAction(1);
                            }}
                            stroke={
                              referencesFeedback[JSON.stringify(reference)] !==
                              1
                            }
                          />
                        </div>
                        <div className="text-cloud-20">|</div>
                        <div className="inline-flex hover:bg-cloud-15 p-1 rounded">
                          <UIIconButton
                            className="focus:outline-none"
                            name="thumb-down"
                            onClick={() => {
                              onFeedbackAction(-1);
                            }}
                            stroke={
                              referencesFeedback[JSON.stringify(reference)] !==
                              -1
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="mt-6 text-sm">
            <span>
              {answerRating === 1
                ? 'Save as a source of truth?'
                : 'Know the correct answer?'}
            </span>{' '}
            <span
              className="cursor-pointer underline text-amethys-10 hover:text-amethys-20"
              onClick={handleAnswer}
            >
              Create Verified Answer
            </span>
          </div>
        </div>
      }
      footer={
        <UIButton
          onClick={() => {
            handleSubmit(true);
          }}
          processing={submitting}
        >
          Submit
        </UIButton>
      }
      footerFirst
      onClose={() => {
        handleSubmit(false);
      }}
      open={open}
    />
  );
};
