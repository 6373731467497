import { gql } from 'graphql-tag';

const appFields = gql`
  fragment AppFields on UsersApp {
    appName
    appUserId
    botOrgWideAccess
    connectionName
    createdAt
    displayName
    email
    userId
    extra
    statusCode
    statusMessage
    backwardCursor
    syncedFrom
    syncedUntil
    org
    id
    workspaceId
    workspaceName
  }
`;

export const userFields = gql`
  fragment UserFields on User {
    email
    sub
    aclKey
    admin
    userId
    nodeId
    onboardingState
    hasSeenWebOnboarding
    homepageLinks
    preferences
    preferredLlm
    reconnectedApps
    orgByOrgId {
      id
      domain
      name
      firstAdminOnboarded
      homepageLinks
      preferences
      exploToken
      isWorkspaceSetup
      appPreferences
      disclaimer
      chatRetentionDays
      preferredLlm
      usersAppsByOrg {
        nodes {
          ...AppFields
        }
      }
      dashSubscriptionsByOrgDomain {
        nodes {
          id
          planType
          status
          trialEndAt
          seatCount
        }
      }
      allowedDomains {
        nodes {
          domain
          status
          verificationCode
          createdAt
          allowAllSignups
        }
      }
    }
    usersApps {
      nodes {
        ...AppFields
      }
    }
  }
  ${appFields}
`;
