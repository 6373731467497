import { useEffect, useState } from 'react';
import { useAppDefinitions } from '../../apps/definition';
import { trackEvent } from '../../extra/sharedMethods';
import {
  Bot,
  RecommendedSlackbotChannel,
  SlackbotChannel,
} from '../../models/Bots';
import { Sources, SupportedLlm, UserApp } from '../../models/User';
import { invokeFastApi } from '../apis/fastapi';
import { AnalyticsEvent } from '../constants/analytics-event';
import { logError } from '../utils';
import { ConnectedApps, filterSortedInstantApps } from './sortedInstantApps';
import { useToaster } from './toast';

export interface UpsertBotParams {
  id?: string;
  icon?: string;
  bot_name?: string;
  description?: string;
  sources?: Sources;
  preferred_llm?: SupportedLlm | null;
  custom_instructions?: string;
}

export interface UpdateSlackbotChannelParams {
  team_id: string;
  channel_id: string;
  is_autorespond?: boolean;
  respond_only_if_answer_found?: boolean;
  bot_id?: string;
  trigger_only_when_tagged?: boolean;
  auto_reply_threshold?: number;
  acknowledge_question?: boolean;
  create_draft?: boolean;
  fallback_message?: string;
}

interface UpsertBotBody {
  icon?: string;
  bot_name?: string;
  description?: string;
  sources?: Sources;
  preferred_llm?: SupportedLlm | null;
  custom_instructions?: string;
}

interface UpdateSlackbotChannelBody {
  is_autorespond?: boolean;
  respond_only_if_answer_found?: boolean;
  bot_id?: string;
  trigger_only_when_tagged?: boolean;
  auto_reply_threshold?: number;
  acknowledge_question?: boolean;
  create_draft?: boolean;
  fallback_message?: string;
}

interface BotsResponse {
  bots: Bot[];
  slackbotChannels: SlackbotChannel[];
  recommendedSlackbotChannels: RecommendedSlackbotChannel[];
  botsEnabledApps: ConnectedApps[];
  botsEnabledUserApps: UserApp[];
  loading: boolean;
  error: boolean;
  getBotsContent: () => Promise<void>;
  createBot: (params: UpsertBotParams) => Promise<void>;
  deleteBot: (id: string) => Promise<void>;
  updateBot: (params: UpsertBotParams) => Promise<void>;
  updateSlackbotChannel: (params: UpdateSlackbotChannelParams) => Promise<void>;
}

// eslint-disable-next-line max-lines-per-function
export const useBots = (): BotsResponse => {
  const [bots, setBots] = useState<Bot[]>([]);

  const [botsEnabledApps, setBotsEnabledApps] = useState<ConnectedApps[]>([]);
  const [botsEnabledUserApps, setBotsEnabledUserApps] = useState<UserApp[]>([]);

  const [slackbotChannels, setSlackbotChannels] = useState<SlackbotChannel[]>(
    []
  );

  const [recommendedSlackbotChannels, setRecommendedSlackbotChannels] =
    useState<RecommendedSlackbotChannel[]>([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const toaster = useToaster();
  const appDefinitions = useAppDefinitions();

  const getBotsContent = async () => {
    setBots([]);
    setSlackbotChannels([]);
    setRecommendedSlackbotChannels([]);
    setLoading(true);
    setError(false);

    try {
      const res = await invokeFastApi({
        path: '/bots',
        method: 'GET',
        queryParams: {
          v2: 'True',
        },
      });

      const {
        bots: fetchedBots,
        enabled_apps: enabled_user_apps,
        slack_channels,
        recommended_channels,
      } = (await res) as {
        bots: Bot[];
        // Gotcha: Backend doesn't send all the fields present in UserApp model
        enabled_apps: UserApp[];
        slack_channels: SlackbotChannel[];
        recommended_channels: RecommendedSlackbotChannel[];
      };

      const { sortedInstantConnectedApps: filteredEnabledApps } =
        filterSortedInstantApps(enabled_user_apps, appDefinitions);

      const filteredEnabledUserApps = enabled_user_apps.filter((userApp) =>
        filteredEnabledApps.some((app) => app.id === userApp.id)
      );

      setBots(fetchedBots);
      setBotsEnabledApps(filteredEnabledApps);
      setBotsEnabledUserApps(filteredEnabledUserApps);
      setSlackbotChannels(slack_channels);
      setRecommendedSlackbotChannels(recommended_channels);
    } catch (fetchError) {
      logError(fetchError);
      setError(true);
      toaster.failure('Failed to fetch bots content');
    }

    setLoading(false);
  };

  const createBot = async ({
    icon,
    bot_name,
    description,
    sources,
    preferred_llm,
    custom_instructions,
  }: UpsertBotParams) => {
    const bodyData: UpsertBotBody = {};

    if (icon !== undefined) {
      bodyData.icon = icon;
    }

    if (bot_name !== undefined) {
      bodyData.bot_name = bot_name;
    }

    if (description !== undefined) {
      bodyData.description = description;
    }

    if (sources !== undefined) {
      bodyData.sources = sources;
    }

    if (preferred_llm !== undefined) {
      bodyData.preferred_llm = preferred_llm;
    }

    if (custom_instructions !== undefined) {
      bodyData.custom_instructions = custom_instructions;
    }

    try {
      await invokeFastApi({
        path: '/bots/create',
        method: 'POST',
        body: bodyData,
      });

      toaster.success('Bot created successfully');
      trackEvent(AnalyticsEvent.CreatedBot, { bot_name });
      getBotsContent();
    } catch (fetchError) {
      logError(fetchError);
      toaster.failure('Failed to create bot');
    }
  };

  const deleteBot = async (id: string) => {
    try {
      await invokeFastApi({
        path: `/bots/${id}`,
        method: 'DELETE',
      });

      setBots((prevBots) => {
        const botIndex = prevBots.findIndex((bot) => bot.id === id);
        if (botIndex === -1) {
          // Bot not found, return the previous state
          return prevBots;
        }

        // Create a new array without the deleted bot
        const newBots = [
          ...prevBots.slice(0, botIndex),
          ...prevBots.slice(botIndex + 1),
        ];

        return newBots;
      });

      toaster.success('Bot deleted successfully');
      trackEvent(AnalyticsEvent.DeletedBot, { bot_id: id });
    } catch (fetchError) {
      logError(fetchError);
      toaster.failure('Failed to delete bot');
    }
  };

  const updateBot = async ({
    id,
    icon,
    bot_name,
    description,
    sources,
    preferred_llm,
    custom_instructions,
  }: UpsertBotParams) => {
    const bodyData: UpsertBotBody = {};

    if (icon !== undefined) {
      bodyData.icon = icon;
    }

    if (bot_name !== undefined) {
      bodyData.bot_name = bot_name;
    }

    if (description !== undefined) {
      bodyData.description = description;
    }

    if (sources !== undefined) {
      bodyData.sources = sources;
    }

    if (preferred_llm !== undefined) {
      bodyData.preferred_llm = preferred_llm;
    }

    if (custom_instructions !== undefined) {
      bodyData.custom_instructions = custom_instructions;
    }

    try {
      if (!id) {
        throw new Error('Bot ID is required');
      }

      await invokeFastApi({
        path: `/bots/${id}`,
        method: 'PATCH',
        body: bodyData,
      });

      setBots((prevBots) => {
        const botIndex = prevBots.findIndex((bot) => bot.id === id);
        if (botIndex === -1) {
          // Bot not found, return the previous state
          return prevBots;
        }

        const prevBot = prevBots[botIndex]!;

        // Create a new bot object with the updated values
        const updatedBot = {
          ...prevBot,
          icon: icon ?? prevBot.icon,
          bot_name: bot_name ?? prevBot.bot_name,
          description: description ?? prevBot.description,
          sources: sources ?? prevBot.sources,
          preferred_llm:
            // eslint-disable-next-line unicorn/no-negated-condition
            preferred_llm !== undefined ? preferred_llm : prevBot.preferred_llm,
          custom_instructions:
            custom_instructions ?? prevBot.custom_instructions,
        };

        // Create a new array with the updated bot
        const newBots = [
          ...prevBots.slice(0, botIndex),
          updatedBot,
          ...prevBots.slice(botIndex + 1),
        ];

        return newBots;
      });

      toaster.success('Bot updated successfully');
      trackEvent(AnalyticsEvent.UpdatedBot, { bot_id: id });
    } catch (fetchError) {
      logError(fetchError);
      toaster.failure('Failed to update bot');
    }
  };

  const updateSlackbotChannel = async ({
    team_id,
    channel_id,
    is_autorespond,
    respond_only_if_answer_found,
    bot_id,
    trigger_only_when_tagged,
    auto_reply_threshold,
    acknowledge_question,
    create_draft,
    fallback_message,
  }: UpdateSlackbotChannelParams) => {
    const bodyData: UpdateSlackbotChannelBody = {};

    if (bot_id !== undefined) {
      bodyData.bot_id = bot_id;
    }

    if (trigger_only_when_tagged !== undefined) {
      bodyData.trigger_only_when_tagged = trigger_only_when_tagged;
    }

    if (auto_reply_threshold !== undefined) {
      bodyData.auto_reply_threshold = auto_reply_threshold;
      bodyData.respond_only_if_answer_found = auto_reply_threshold > 0;
      bodyData.is_autorespond = auto_reply_threshold > 0;
    }

    if (acknowledge_question !== undefined) {
      bodyData.acknowledge_question = acknowledge_question;
    }

    if (create_draft !== undefined) {
      bodyData.create_draft = create_draft;
    }

    if (fallback_message !== undefined) {
      bodyData.fallback_message = fallback_message;
    }

    try {
      await invokeFastApi({
        // eslint-disable-next-line @cspell/spellchecker
        path: `/bots/slack/channels/${team_id}/${channel_id}`,
        method: 'PATCH',
        body: bodyData,
      });

      setSlackbotChannels((prevChannels) => {
        const channelIndex = prevChannels.findIndex(
          (channel) =>
            channel.team_id === team_id && channel.channel_id === channel_id
        );

        if (channelIndex === -1) {
          // Channel not found, return the previous state
          return prevChannels;
        }

        const prevChannel = prevChannels[channelIndex]!;

        // Create a new channel object with the updated values
        const updatedChannel = {
          ...prevChannel,
          is_autorespond: is_autorespond ?? prevChannel.is_autorespond,
          respond_only_if_answer_found:
            respond_only_if_answer_found ??
            prevChannel.respond_only_if_answer_found,
          bot_id: bot_id ?? prevChannel.bot_id,
          trigger_only_when_tagged:
            trigger_only_when_tagged ?? prevChannel.trigger_only_when_tagged,
          auto_reply_threshold:
            auto_reply_threshold ?? prevChannel.auto_reply_threshold,
          acknowledge_question:
            acknowledge_question ?? prevChannel.acknowledge_question,
          create_draft: create_draft ?? prevChannel.create_draft,
          fallback_message: fallback_message ?? prevChannel.fallback_message,
        };

        // Create a new array with the updated bot
        const newChannels = [
          ...prevChannels.slice(0, channelIndex),
          updatedChannel,
          ...prevChannels.slice(channelIndex + 1),
        ];

        return newChannels;
      });

      toaster.success('Slack channel updated successfully');
    } catch (fetchError) {
      logError(fetchError);
      toaster.failure('Failed to update slack channel');
    }
  };

  useEffect(() => {
    getBotsContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    bots,
    slackbotChannels,
    recommendedSlackbotChannels,
    botsEnabledApps,
    botsEnabledUserApps,
    loading,
    error,
    getBotsContent,
    createBot,
    deleteBot,
    updateBot,
    updateSlackbotChannel,
  };
};
