import React, { FC, useCallback, useState } from 'react';
import { invokeFastApi } from '../../../scripts/apis/fastapi';
import { useToaster } from '../../../scripts/hooks/toast';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '../../shadcn/lib/components/popover';

interface StopSharingPopoverProps {
  conversationId?: string;
  setIsOrgVisibility: (value: boolean) => void;
}

export const StopSharingPopover: FC<StopSharingPopoverProps> = ({
  conversationId,
  setIsOrgVisibility,
}) => {
  const toaster = useToaster();
  const [showShareSettings, setShowShareSettings] = useState(false);

  const handleStopSharing = useCallback(async () => {
    if (!conversationId) {
      toaster.failure('Failed to stop sharing');
      return;
    }

    try {
      await invokeFastApi({
        path: `/topics/topic/${conversationId}`,
        method: 'PATCH',
        body: { visibility: 'PRIVATE' },
      });

      setIsOrgVisibility(false);
      toaster.success('Stopped sharing topic');
    } catch {
      toaster.failure('Failed to stop sharing');
    }
  }, [conversationId, setIsOrgVisibility, toaster]);

  return (
    <Popover onOpenChange={setShowShareSettings} open={showShareSettings}>
      <PopoverTrigger asChild>
        <div className="flex gap-1">
          <div
            className="flex p-0.5 rounded hover:bg-cloud-15"
            onClick={() => {
              setShowShareSettings(!showShareSettings);
            }}
          >
            <UIIcon name="group" tooltip="Share settings" />
          </div>
          <div className="text-cloud-20 text-sm hidden sm:block">|</div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="z-10 bg-white sm:w-[424px] w-[250px]">
        <div className="font-semibold">You shared this topic</div>
        <ul className="pl-4 text-gray-50 text-sm">
          <li>All the messages in this topic will be shared.</li>
          <li>Anyone in your org will be able to access this link.</li>
          <li>Reference previews will be shared.</li>
          <li>Original document access permissions are still honored.</li>
        </ul>
        <div className="flex justify-between items-center mt-4">
          <PopoverClose asChild>
            <UIButton
              className="flex gap-2"
              onClick={handleStopSharing}
              type="primary"
            >
              <UIIcon name="visibility-off" />
              Stop Sharing
            </UIButton>
          </PopoverClose>
        </div>
      </PopoverContent>
    </Popover>
  );
};
