import { Box, Tab, Tabs } from '@mui/material';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { PaywallModal } from '../../components/admin/EnterprisePaywall/EnterprisePaywall';
import { AuthorDetails } from '../../components/controls/AuthorDetails/AuthorDetails';
import { Loading } from '../../components/controls/Loading/Loading';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { UIIcon } from '../../components/controls/ui/UIIcon/UIIcon';
import { UITextbox } from '../../components/controls/ui/UITextbox/UITextbox';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/shadcn/lib/components/table';
import { QAWorkflowTemplateModal } from '../../components/workflowModal/QAWorkflowTemplateModal';
import { BlankStatePersonalTab } from '../../components/workflowModal/WorkflowTemplateTable';
import { trackEvent } from '../../extra/sharedMethods';
import { Workflow, WorkflowModalTypes } from '../../models/Workflows';
import { useQAController } from '../../scripts/QAController';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useBoolState, useUserSafe } from '../../scripts/hooks';
import { useWorkflows } from '../../scripts/hooks/workflows';

export const WorkflowsPage: React.FC = () => {
  const { fetchWorkflows, workflows, loading } = useWorkflows();
  const qaController = useQAController();

  const [openPaywallModal, setOpenPaywallModal, setNotOpenPaywallModal] =
    useBoolState(false);

  const handleCreateNewWorkflow = useCallback(() => {
    qaController.triggerEvent('showWorkflowModal', {
      workflowModalType: WorkflowModalTypes.CREATE,
    });
  }, [qaController]);

  return (
    <div className="mx-2 lg:mx-auto border-transparent my-24 max-w-5xl">
      <div className="flex gap-2 items-center">
        <UIIcon name="bolt" size={32} />
        <h2 className="text-2xl m-0 font-bold">Workflows</h2>
      </div>
      <div className="flex p-6 gap-4 rounded-lg bg-cloud-15 justify-between items-center mt-9 max-sm:p-4">
        <div>
          Workflows let you save, reuse, and share templates in Dash AI.
        </div>
        <UIButton onClick={handleCreateNewWorkflow}>New Workflow</UIButton>
      </div>
      <WorkflowTemplateTable
        fetchWorkflows={fetchWorkflows}
        loading={loading}
        workflows={workflows}
      />
      <QAWorkflowTemplateModal
        refetchWorkflows={fetchWorkflows}
        setOpenPaywallModal={setOpenPaywallModal}
      />
      {openPaywallModal && (
        <PaywallModal allowUpgrade closeModal={setNotOpenPaywallModal} />
      )}
    </div>
  );
};

interface IWorkflowTemplateTableProps {
  workflows: Workflow[];
  loading: boolean;
  fetchWorkflows: (searchText?: string) => void;
}

const WorkflowTemplateTable = ({
  workflows,
  loading,
  fetchWorkflows,
}: IWorkflowTemplateTableProps) => {
  const user = useUserSafe();
  const [activeTab, setActiveTab] = useState(0);
  const [filteredWorkflows, setFilteredWorkflows] =
    useState<Workflow[]>(workflows);

  const [searchText, setSearchText] = useState<string>('');

  const qaController = useQAController();

  const handleRowClick = useCallback(
    (author: string, isDefault: boolean, workflowId: string) => {
      const isAuthor = user.email === author;

      trackEvent(AnalyticsEvent.WorkflowViewed, { promptId: workflowId });

      qaController.triggerEvent('showWorkflowModal', {
        isDefault,
        isAuthor,
        workflowId,
        workflowModalType: WorkflowModalTypes.VIEW_TEMPLATE,
      });
    },
    [qaController, user.email]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchWorkflows = useCallback(
    debounce((value?: string) => {
      fetchWorkflows(value === '' ? undefined : value);
    }, 150),
    []
  );

  const handleSearch = useCallback(
    (value: string) => {
      setSearchText(value);
      debouncedFetchWorkflows(value === '' ? undefined : value);
    },
    [debouncedFetchWorkflows]
  );

  const handleTabChange = useCallback(
    (_, newValue: number) => {
      setActiveTab(newValue);
      switch (newValue) {
        case 0: {
          setFilteredWorkflows(workflows);

          break;
        }

        case 1: {
          setFilteredWorkflows(
            workflows
              .filter((workflow) => workflow.author.email === user.email)
              .sort((a, b) => b.created_at - a.created_at)
          );

          break;
        }
      }
    },
    [workflows, user]
  );

  useEffect(() => {
    handleTabChange(null, activeTab);
  }, [activeTab, handleTabChange, workflows]);

  return (
    <div>
      <Box
        className="mt-10 uiTabs MuiTabs-root Mui-selected"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tabs onChange={handleTabChange} value={activeTab} variant="scrollable">
          <Tab
            label="All"
            sx={{
              textTransform: 'none',
            }}
          />
          <Tab
            label="Personal"
            sx={{
              textTransform: 'none',
            }}
          />
        </Tabs>
      </Box>
      <div className="my-4">
        <UITextbox
          defaultWhiteInputField
          onChange={handleSearch}
          placeholder="Search workflows ..."
          value={searchText}
        />
      </div>
      {!loading && filteredWorkflows.length === 0 && activeTab === 1 ? (
        <BlankStatePersonalTab />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-3/5">Name</TableHead>
              <TableHead className="w-3/10">Created By</TableHead>
              <TableHead className="w-1/10">Uses</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredWorkflows.map((row) => (
              <TableRow
                className="cursor-pointer"
                key={row.id}
                onClick={() => {
                  handleRowClick(row.author.email, row.default, row.id);
                }}
              >
                <TableCell>
                  <div className="flex flex-col gap-2">
                    <div className="font-medium">{row.title}</div>
                    <div className="font-normal text-cloud-30">
                      {row.description && row.description.length > 90
                        ? `${row.description.slice(0, 90)}...`
                        : row.description}
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <AuthorDetails
                    displayName={row.author.display_name}
                    icon={row.author.icon}
                    isDefault={row.default}
                  />
                </TableCell>
                <TableCell>{row.default ? '-' : row.uses}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {loading && <Loading />}
    </div>
  );
};
