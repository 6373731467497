import { sanitize } from 'dompurify';
import React, { FC } from 'react';

import './HTMLPreview.scss';

export const HTMLPreview: FC<{ content: string }> = ({ content }) => {
  return (
    <div
      className="htmlPreview"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: sanitize(content),
      }}
    />
  );
};
