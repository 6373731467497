import classNames from 'classnames';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SidebarRoutes } from '../../containers/SidebarContainer';
import { trackEvent } from '../../extra/sharedMethods';
import { QAMessage } from '../../models/QAmodels';
import { useQAController } from '../../scripts/QAController';
import { invokeFastApi } from '../../scripts/apis/fastapi';
import {
  FeedbackRequestBody,
  sendFeedback,
} from '../../scripts/apis/qaFeedback';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useToaster } from '../../scripts/hooks';
import {
  formatReferences,
  logError,
  stripReferences,
} from '../../scripts/utils';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { UIIconButton } from '../controls/ui/UIIconButton/UIIconButton';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '../shadcn/lib/components/hoverCard';

interface IQAActionRow {
  qaMessage: QAMessage;
  sharableConversation?: QAMessage[];
}

const Divider = () => <div className="text-cloud-20">|</div>;

export const QAActionRow: FC<IQAActionRow> = ({
  qaMessage,
  sharableConversation,
}) => {
  // feedback: +1, -1, undefined
  const [feedbackGiven, setFeedbackGiven] = useState(
    qaMessage.extraData?.messageFeedback?.rating
  );

  const [openCopyModal, setOpenCopyModal] = useState(false);

  const history = useHistory();

  const toaster = useToaster();
  const qaController = useQAController();

  const parentMessage = qaController.getUserMessageForAssistantAnswer(
    qaMessage.row_id,
    sharableConversation
  );

  const isDashAi = useMemo(
    () => window.location.pathname === SidebarRoutes.JitQA,
    []
  );

  const handleFeedback = useCallback(
    (feedback: number) => {
      trackEvent(AnalyticsEvent.QAMessageRate, {
        feedback,
        answer_id: qaMessage.row_id,
      });

      if (feedbackGiven === feedback) {
        const feedbackBody: FeedbackRequestBody = {
          query_id: parentMessage!.row_id.replace(/-/g, ''),
          response_id: qaMessage.row_id.replace(/-/g, ''),
          references_feedback: {},
        };

        sendFeedback(feedbackBody)
          .then(() => {
            setFeedbackGiven(undefined);
            toaster.success(
              `${feedback === 1 ? 'Upvote' : 'Downvote'} removed.`
            );
          })
          .catch((error) => {
            logError(error);
            toaster.failure(
              `Error undoing ${
                feedback === 1 ? 'upvote' : 'downvote'
              }. Please try again.`
            );
          });
      } else {
        setFeedbackGiven(feedback);

        qaController.triggerEvent('showFeedbackModal', {
          response_id: qaMessage.row_id,
          response_references: qaMessage.references,
          response_rating: feedback,
        });
      }
    },
    [qaMessage, feedbackGiven, qaController, parentMessage, toaster]
  );

  const handleCopyLink = useCallback(async () => {
    if (!qaMessage.conversation_id) {
      toaster.failure('Failed to copy shareable link');
      return;
    }

    const sharableLink = `${window.location.origin}/topic/${qaMessage.conversation_id}`;
    navigator.clipboard.writeText(sharableLink);
    try {
      await invokeFastApi({
        path: `/topics/topic/${qaMessage.conversation_id}`,
        method: 'PATCH',
        body: { visibility: 'ORG' },
      });

      qaController.updateShareableConversation(
        qaMessage.conversation_id,
        'ORG'
      );
    } catch {
      toaster.failure('Failed to share topic');
      return;
    }

    setOpenCopyModal(false);
    toaster.success('Copied shareable link to clipboard');
    trackEvent(AnalyticsEvent.SharableLinkCreated);
  }, [qaMessage.conversation_id, toaster, qaController]);

  const handleCopyText = useCallback(() => {
    if (qaMessage.sender === 'ASSISTANT') {
      trackEvent(AnalyticsEvent.QAMessageResponseUserCopiedText);
    }

    const strippedText = stripReferences(qaMessage.messageText, true);
    navigator.clipboard.writeText(strippedText);

    setOpenCopyModal(false);
    toaster.success('Copied text to clipboard');
  }, [qaMessage.sender, qaMessage.messageText, toaster]);

  const handleCopyTextReferences = useCallback(() => {
    if (qaMessage.sender === 'ASSISTANT') {
      trackEvent(AnalyticsEvent.QAMessageResponseUserCopiedTextReferences);
    }

    const formattedText = formatReferences(qaMessage.messageText, true);
    navigator.clipboard.writeText(formattedText);

    setOpenCopyModal(false);
    toaster.success('Copied text and references to clipboard');
  }, [qaMessage.sender, qaMessage.messageText, toaster]);

  const handleSaveAsWorkflow = useCallback(() => {
    qaController.triggerEvent('saveWorkflow', {
      workflowTemplate: parentMessage?.messageText,
    });
  }, [qaController, parentMessage?.messageText]);

  return (
    <div
      className={classNames(
        'my-4 border border-cloud-15 border-solid shadow-flat rounded p-1 bg-white inline-flex gap-1 transition-opacity duration-300'
      )}
    >
      <div className="inline-flex hover:bg-cloud-15 p-1 rounded">
        <UIIconButton
          className="focus:outline-none"
          name="thumb-up"
          onClick={() => {
            handleFeedback(1);
          }}
          stroke={feedbackGiven !== 1}
          tooltip="Accurate answer"
        />
      </div>
      <Divider />
      <div className="flex hover:bg-cloud-15 p-1 rounded">
        <UIIconButton
          className="focus:outline-none"
          name="thumb-down"
          onClick={() => {
            handleFeedback(-1);
          }}
          stroke={feedbackGiven !== -1}
          tooltip="Inaccurate answer"
        />
      </div>
      <Divider />
      <HoverCard
        closeDelay={150}
        onOpenChange={() => {
          setOpenCopyModal(!openCopyModal);
        }}
        open={openCopyModal}
        openDelay={150}
      >
        <HoverCardTrigger asChild>
          <div className="flex hover:bg-cloud-15 p-1 rounded text-black cursor-pointer">
            <UIIcon name="copy" />
          </div>
        </HoverCardTrigger>
        <HoverCardContent className="p-0 flex flex-col">
          <div
            className="p-2 flex gap-2 items-center hover:bg-cloud-10 hover:cursor-pointer"
            onClick={handleCopyLink}
          >
            <UIIcon name="link" size={20} />
            <div className="text-sm font-medium">Copy URL</div>
          </div>
          {qaMessage.references.length > 0 && (
            <div
              className="p-2 flex gap-2 items-center hover:bg-cloud-10 hover:cursor-pointer"
              onClick={handleCopyTextReferences}
            >
              <UIIcon name="editor-quote" size={20} />
              <div className="text-sm font-medium">
                Copy Text and References
              </div>
            </div>
          )}
          <div
            className="p-2 flex gap-2 items-center hover:bg-cloud-10 hover:cursor-pointer"
            onClick={handleCopyText}
          >
            <UIIcon name="copy" size={20} />
            <div className="text-sm font-medium">Copy Text</div>
          </div>
        </HoverCardContent>
      </HoverCard>
      {isDashAi && (
        <>
          <Divider />

          <div
            className="flex hover:bg-cloud-15 p-1 rounded"
            onClick={handleSaveAsWorkflow}
          >
            <UIIconButton
              name="electric-bolt-with-plus"
              onClick={handleSaveAsWorkflow}
              tooltip="Save as workflow"
            />
          </div>
        </>
      )}
      <Divider />
      <div
        className="flex hover:bg-cloud-15 p-1 rounded cursor-pointer"
        onClick={handleCopyLink}
      >
        <UIIcon name="link" tooltip="Share a link to this topic" />
      </div>
      {isDashAi && (
        <>
          <Divider />
          <div
            className="flex hover:bg-cloud-15 p-1 rounded cursor-pointer"
            onClick={() => {
              history.push(`/topic/${qaMessage.conversation_id}`);
              trackEvent(AnalyticsEvent.ContinueTopic);
            }}
          >
            <UIIcon name="add-comment" tooltip="Continue Topic" />
          </div>
        </>
      )}
    </div>
  );
};
