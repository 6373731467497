import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { store } from '../../../redux/store';
import { invokeFastApi } from '../../../scripts/apis/fastapi';
import { useToaster } from '../../../scripts/hooks';
import { FileVisibility, UploadStatus } from '../../../scripts/hooks/files';
import { ToastType } from '../../../scripts/models/toast';
import { logError } from '../../../scripts/utils';

export interface UploadedFileResponse {
  id: string;
  name: string;
}

export interface QAFile {
  id: string;
  title: string;
}

interface QAFilesProps {
  uploadedFiles: QAFile[] | null;
  uploadFiles: (files: File[]) => void;
  deleteFile: (documentId: string) => Promise<void>;
  resetUploadedFiles: () => void;
  uploadStatus: UploadStatus;
}

const QAFilesContext = createContext<QAFilesProps | undefined>(undefined);

export const useQAFiles = (): QAFilesProps => {
  const ctx = useContext(QAFilesContext);
  if (!ctx) {
    throw new Error('Attempted to use context outside of scope');
  }

  return ctx;
};

interface QAFileProviderProps {
  children: ReactNode;
}

export const QAFilesProvider = ({
  children,
}: QAFileProviderProps): JSX.Element => {
  const [uploadedFiles, setUploadedFiles] = useState<
    | {
        id: string;
        title: string;
      }[]
    | null
  >(null);

  const [uploadStatus, setUploadStatus] = useState(UploadStatus.PENDING);
  const toaster = useToaster();

  const uploadFiles = useCallback(
    async (files: File[]): Promise<void> => {
      setUploadStatus(UploadStatus.TRANSIT);
      const FILE_UPLOADING_TOAST_UID = 'uploading-qa-files';

      try {
        const filesCountMessage =
          files.length > 1 ? `${files.length} Files` : 'File';

        toaster.create({
          type: ToastType.LOADING,
          message: `Uploading ${filesCountMessage}. Please keep tab open`,
          uid: FILE_UPLOADING_TOAST_UID,
        });

        const { tokens } = store.getState();
        const id_token = tokens?.loginTokens?.id_token;

        const uploadPromises = files.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('visibility', FileVisibility.CHAT);

          const response = await fetch(`${SEARCH_URL}/document_uploads`, {
            headers: {
              ...(id_token ? { authorization: `Bearer ${id_token}` } : {}),
            },
            method: 'POST',
            body: formData,
          });

          return response;
        });

        const responses = await Promise.all(uploadPromises);

        const failedUploads = responses.filter((response) => !response.ok);
        if (failedUploads.length > 0) {
          throw new Error('Failed to upload some files');
        }

        const uploadedFilesData = await Promise.all(
          responses.map(async (response) => {
            const data = (await response.json()) as {
              document: UploadedFileResponse;
            };

            return data;
          })
        );

        setUploadedFiles((prev) => {
          const newFiles = uploadedFilesData.map((item) => ({
            id: item.document.id,
            title: item.document.name,
          }));

          return prev ? [...prev, ...newFiles] : newFiles;
        });

        setUploadStatus(UploadStatus.DONE);
        toaster.success('Files uploaded successfully');
      } catch (error) {
        logError(error);

        toaster.failure('Failed to upload files');
        setUploadStatus(UploadStatus.PENDING);
      } finally {
        toaster.delete(FILE_UPLOADING_TOAST_UID);
      }
    },
    [toaster]
  );

  const deleteFileHandler = useCallback(async (documentId: string) => {
    setUploadedFiles((prev) =>
      prev ? prev.filter((file) => file.id !== documentId) : null
    );

    try {
      await invokeFastApi({
        path: `/document_uploads/${documentId}`,
        method: 'DELETE',
      });
    } catch (error) {
      logError(error);
    }
  }, []);

  const resetUploadedFiles = useCallback(() => {
    setUploadedFiles(null);
  }, []);

  const value = useMemo(
    () => ({
      uploadedFiles,
      uploadFiles,
      deleteFile: deleteFileHandler,
      resetUploadedFiles,
      uploadStatus,
    }),
    [
      uploadedFiles,
      uploadFiles,
      deleteFileHandler,
      resetUploadedFiles,
      uploadStatus,
    ]
  );

  return (
    <QAFilesContext.Provider value={value}>{children}</QAFilesContext.Provider>
  );
};
