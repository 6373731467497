import { Box, Tab, Tabs } from '@mui/material';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import botsImage from '../../assets/images/bots.png';
import {
  EnterpriseIcon,
  PaywallModal,
} from '../../components/admin/EnterprisePaywall/EnterprisePaywall';
import { BotsModal } from '../../components/bots/BotsModal';
import { ChannelsModal } from '../../components/bots/ChannelsModal';
import { Loading } from '../../components/controls/Loading/Loading';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { UIIcon } from '../../components/controls/ui/UIIcon/UIIcon';
import { Feature, useFeatureAccess } from '../../hooks/subscriptionHooks';
import {
  Bot,
  RecommendedSlackbotChannel,
  SlackbotChannel,
} from '../../models/Bots';
import { useBoolState, useServiceAccount } from '../../scripts/hooks';
import { useBots } from '../../scripts/hooks/bots';
import {
  AutoJoinChannelBody,
  useRecommendedSlackbotChannels,
} from '../../scripts/hooks/recommendedSlackbotChannels';
import { BotsTab } from './tabs/BotsTab';
import { RecommendedSlackbotChannelsTab } from './tabs/RecommendedSlackbotChannelsTab';
import { SlackbotChannelsTab } from './tabs/SlackbotChannelsTab';

export const BotsPage: React.FC = () => {
  const [currentBot, setCurrentBot] = useState<Bot | undefined>(undefined);
  const [showCreateBotModal, setShowCreateBotModal] = useState(false);
  const [currentChannel, setCurrentChannel] = useState<
    SlackbotChannel | undefined
  >(undefined);

  const [openPaywallModal, setOpenPaywallModal, setNotOpenPaywallModal] =
    useBoolState(false);

  const hasCustomBotsFeature = useFeatureAccess(Feature.CUSTOM_BOTS);

  const {
    bots,
    slackbotChannels,
    recommendedSlackbotChannels,
    botsEnabledApps,
    botsEnabledUserApps,
    loading,
    getBotsContent,
    createBot,
    deleteBot,
    updateBot,
    updateSlackbotChannel,
  } = useBots();

  const { autoJoinRecommendedSlackbotChannel } =
    useRecommendedSlackbotChannels();

  const { serviceAccountEmail } = useServiceAccount();

  const closeBotsModal = useCallback(() => {
    setCurrentBot(undefined);
    setShowCreateBotModal(false);
  }, []);

  const closeChannelsModal = useCallback(() => {
    setCurrentChannel(undefined);
  }, []);

  const handleCreateBotClick = useCallback(() => {
    if (!hasCustomBotsFeature) {
      setOpenPaywallModal();
      return;
    }

    setCurrentBot(undefined);
    setShowCreateBotModal(true);
  }, [hasCustomBotsFeature, setOpenPaywallModal]);

  return (
    <div className="mx-2 lg:mx-auto border-transparent my-24 max-w-5xl">
      <div className="flex gap-2 items-center">
        <UIIcon name="bot" size={32} />
        <h2 className="text-2xl m-0 font-bold">Bots</h2>
      </div>

      {!loading && bots.length === 0 && (
        <div className="flex flex-col gap-10">
          <div className="flex p-6 gap-4 rounded-lg bg-cloud-15 justify-between items-center mt-9 max-sm:p-4">
            <div className="p-2 bg-white rounded-full">
              <UIIcon name="info" size={24} />
            </div>
            <div>
              Create Bots for different use cases using tailored knowledge
              sources and instructions. Tag them on the web app with "@Name" or
              invite them to a Slack channel with "/invite @Dashworks".
            </div>
          </div>
          <div className="max-w-3xl flex flex-col gap-10">
            <img className="w-full" src={botsImage} />
            <div className="flex flex-col gap-1">
              <div className="font-semibold">Setup a bot account</div>
              <div>
                Bots use the account permissions of a bot account to find
                answers to questions asked in Dashworks. An admin is required to
                complete this step. Click{' '}
                <a
                  href="https://support.dashworks.ai/bots/setup-bot-account"
                  rel="noreferrer"
                  target="_blank"
                >
                  here
                </a>{' '}
                to learn more.
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading && bots.length !== 0 && (
        <div>
          <div className="flex p-6 gap-4 rounded-lg bg-cloud-15 justify-between items-center mt-10 max-sm:p-4">
            <div className="p-2 bg-white rounded-full">
              <UIIcon name="info" size={24} />
            </div>
            <div>
              Create Bots for different use cases using tailored knowledge
              sources and instructions. Tag them on the web app with "@Name" or
              invite them to a Slack channel with "/invite @Dashworks".
            </div>
            <UIButton
              onClick={handleCreateBotClick}
              type={hasCustomBotsFeature ? 'primary' : 'secondary'}
            >
              {!hasCustomBotsFeature && <EnterpriseIcon />}
              Create Bot
            </UIButton>
          </div>

          <BotsTable
            autoJoinRecommendedSlackbotChannel={
              autoJoinRecommendedSlackbotChannel
            }
            bots={bots}
            getBotsContent={getBotsContent}
            recommendedSlackbotChannels={recommendedSlackbotChannels}
            serviceAccountEmail={serviceAccountEmail}
            setCurrentBot={setCurrentBot}
            setCurrentChannel={setCurrentChannel}
            showRecommended={recommendedSlackbotChannels.length > 0}
            slackbotChannels={slackbotChannels}
          />
        </div>
      )}

      {loading && <Loading />}

      {(currentBot !== undefined || showCreateBotModal) && (
        <BotsModal
          bot={currentBot}
          botsEnabledApps={botsEnabledApps}
          botsEnabledUserApps={botsEnabledUserApps}
          closeModal={closeBotsModal}
          createBot={createBot}
          deleteBot={deleteBot}
          isInSlackChannel={slackbotChannels.some(
            (channel) => channel.bot_id === currentBot?.id
          )}
          serviceAccountEmail={serviceAccountEmail}
          setOpenPaywallModal={setOpenPaywallModal}
          type={showCreateBotModal ? 'create' : 'edit'}
          updateBot={updateBot}
        />
      )}
      {currentChannel !== undefined && (
        <ChannelsModal
          bots={bots}
          channel={currentChannel}
          closeModal={closeChannelsModal}
          updateSlackbotChannel={updateSlackbotChannel}
        />
      )}
      {openPaywallModal && (
        <PaywallModal allowUpgrade closeModal={setNotOpenPaywallModal} />
      )}
    </div>
  );
};

interface BotsTableProps {
  showRecommended: boolean;
  bots: Bot[];
  slackbotChannels: SlackbotChannel[];
  recommendedSlackbotChannels: RecommendedSlackbotChannel[];
  serviceAccountEmail: string | null;
  setCurrentBot: (bot: Bot) => void;
  setCurrentChannel: (channel: SlackbotChannel) => void;
  getBotsContent: () => Promise<void>;
  autoJoinRecommendedSlackbotChannel: (
    body: AutoJoinChannelBody,
    autoJoinFailureToastMessage: ReactNode
  ) => Promise<void>;
}

const BotsTable = ({
  showRecommended,
  bots,
  slackbotChannels,
  recommendedSlackbotChannels,
  serviceAccountEmail,
  setCurrentBot,
  setCurrentChannel,
  getBotsContent,
  autoJoinRecommendedSlackbotChannel,
}: BotsTableProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = useCallback((_, newValue: number) => {
    setActiveTab(newValue);
  }, []);

  useEffect(() => {
    handleTabChange(null, activeTab);
  }, [activeTab, handleTabChange, bots]);

  return (
    <div>
      <Box
        className="mt-10 uiTabs MuiTabs-root Mui-selected"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tabs onChange={handleTabChange} value={activeTab} variant="scrollable">
          <Tab
            label="Bots"
            sx={{
              textTransform: 'none',
            }}
          />
          {showRecommended && (
            <Tab
              label="Recommended Slack Channels"
              sx={{
                textTransform: 'none',
              }}
            />
          )}
          <Tab
            label="Slack Channels"
            sx={{
              textTransform: 'none',
            }}
          />
        </Tabs>
      </Box>
      {activeTab === 0 && <BotsTab bots={bots} setCurrentBot={setCurrentBot} />}
      {activeTab === 1 &&
        (showRecommended ? (
          <RecommendedSlackbotChannelsTab
            autoJoinRecommendedSlackbotChannel={
              autoJoinRecommendedSlackbotChannel
            }
            getBotsContent={getBotsContent}
            recommendedSlackbotChannels={recommendedSlackbotChannels}
          />
        ) : (
          <SlackbotChannelsTab
            serviceAccountEmail={serviceAccountEmail}
            setCurrentChannel={setCurrentChannel}
            slackbotChannels={slackbotChannels}
          />
        ))}
      {activeTab === 2 && (
        <SlackbotChannelsTab
          serviceAccountEmail={serviceAccountEmail}
          setCurrentChannel={setCurrentChannel}
          slackbotChannels={slackbotChannels}
        />
      )}
    </div>
  );
};
