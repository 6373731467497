import Knock, { Feed, FeedItem, FeedMetadata } from '@knocklabs/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFlag, useGlobalState } from '../../scripts/hooks';
import {
  getKnockNotificationsToken,
  markFeedItemsAsRead,
  mergeFeedItems,
  NotificationsFeedItem,
  processFeedItems,
  updateMetaDataAfterNotificationsRead,
} from './utils';

interface NotificationsProps {
  notificationItems: NotificationsFeedItem[] | null;
  metaData: FeedMetadata | null;
  markNotificationAsRead: (feedItem: FeedItem) => void;
  markAllNotificationsAsRead: () => void;
}

const NotificationsContext = createContext<NotificationsProps | undefined>(
  undefined
);

export const useNotifications = (): NotificationsProps => {
  const ctx = useContext(NotificationsContext);
  if (!ctx) {
    throw new Error('Attempted to use context outside of scope');
  }

  return ctx;
};

interface NotificationsProviderProps {
  children: ReactNode;
}

export const NotificationsProvider = ({
  children,
}: NotificationsProviderProps): JSX.Element => {
  const { user, tokens } = useGlobalState((state) => state);
  const [knockToken, setKnockToken] = useState<string | null>(null);
  const [notificationsFeed, setNotificationsFeed] = useState<Feed | null>(null);
  const [notificationItems, setNotificationItems] = useState<
    NotificationsFeedItem[] | null
  >(null);

  const knockNotificationsEnabled = useFlag('knockNotificationsEnabled');

  const [metaData, setMetaData] = useState<FeedMetadata | null>(null);

  useEffect(() => {
    async function getKnockToken() {
      if (tokens?.loginTokens?.id_token) {
        const knockTokenRes = await getKnockNotificationsToken();
        setKnockToken(knockTokenRes);
      }
    }

    if (knockNotificationsEnabled) {
      getKnockToken();
    }
  }, [tokens?.loginTokens?.id_token, knockNotificationsEnabled]);

  useEffect(() => {
    if (user?.userId && knockToken) {
      const knock = new Knock(KNOCK_PUBLIC_API_KEY);
      knock.authenticate(user.userId, knockToken);

      const knockFeed = knock.feeds.initialize(KNOCK_FEED_CHANNEL_ID);
      setNotificationsFeed(knockFeed);

      knockFeed.listenForUpdates();

      knockFeed.fetch();

      knockFeed.on(
        'items.received.realtime',
        (data: { items: FeedItem[]; metadata: FeedMetadata }) => {
          setNotificationItems((prev) => {
            return [...(prev ?? []), ...processFeedItems(data.items)].sort(
              (a, b) =>
                a.knockItem.inserted_at > b.knockItem.inserted_at ? -1 : 1
            );
          });

          setMetaData(data.metadata);
        }
      );

      knockFeed.on('items.read', (data: { items: FeedItem[] }) => {
        setNotificationItems((prev) =>
          mergeFeedItems(prev ?? [], markFeedItemsAsRead(data.items))
        );

        setMetaData((prev) =>
          updateMetaDataAfterNotificationsRead(prev, data.items)
        );
      });

      knockFeed.on('items.all_read', (data: { items: FeedItem[] }) => {
        setNotificationItems((prev) =>
          mergeFeedItems(prev ?? [], markFeedItemsAsRead(data.items))
        );

        setMetaData((prev) =>
          updateMetaDataAfterNotificationsRead(prev, data.items)
        );
      });

      knockFeed.on(
        'items.received.page',
        ({
          items,
          metadata,
        }: {
          items: FeedItem[];
          metadata: FeedMetadata;
        }) => {
          setNotificationItems(processFeedItems(items));
          setMetaData(metadata);
        }
      );

      return () => {
        knock.teardown();
        knockFeed.teardown();
      };
    }
  }, [user?.userId, knockToken]);

  const markNotificationAsRead = useCallback(
    (feedItem: FeedItem) => {
      if (!notificationsFeed) return;
      notificationsFeed.markAsRead(feedItem);
    },
    [notificationsFeed]
  );

  const markAllNotificationsAsRead = useCallback(() => {
    if (!notificationsFeed) return;
    notificationsFeed.markAllAsRead();
  }, [notificationsFeed]);

  const contextValue = useMemo(
    () => ({
      markAllNotificationsAsRead,
      markNotificationAsRead,
      notificationItems,
      metaData,
    }),
    [
      markAllNotificationsAsRead,
      markNotificationAsRead,
      notificationItems,
      metaData,
    ]
  );

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
    </NotificationsContext.Provider>
  );
};
