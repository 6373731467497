import React, { useCallback, useMemo, useState } from 'react';
import { setSidebarNotificationsOpen } from '../../redux/sidebar/actions';
import { useDispatch } from '../../redux/store';
import { Loading } from '../controls/Loading/Loading';
import { RadioList } from '../controls/RadioList/RadioList';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { UIIconButton } from '../controls/ui/UIIconButton/UIIconButton';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../shadcn/lib/components/popover';
import { NotificationsCard } from './NotificationsCard';
import { useNotifications } from './NotificationsContext';
import { NOTIFICATION_FILTERS_OPTIONS, NotificationsFilter } from './utils';

export const NotificationsFeed = (): JSX.Element => {
  const { notificationItems, markAllNotificationsAsRead } = useNotifications();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<NotificationsFilter>(
    NotificationsFilter.All
  );

  const [openSelect, setOpenSelect] = useState(false);

  const closeNotificationsSidebar = useCallback(() => {
    dispatch(setSidebarNotificationsOpen(false));
  }, [dispatch]);

  const filteredItems = useMemo(() => {
    return notificationItems?.filter((item) => {
      if (filter === NotificationsFilter.Read) return item.read;
      if (filter === NotificationsFilter.Unread) return !item.read;
      return true;
    });
  }, [notificationItems, filter]);

  return (
    <div className="notifications h-full relative">
      <div className="p-4 flex justify-between items-center">
        <div className="flex items-center justify-center gap-1">
          <UIIcon name="bell" />
          <div>Inbox</div>
        </div>

        <div className="flex items-center text-gray-50 text-sm gap-3">
          <Popover modal onOpenChange={setOpenSelect} open={openSelect}>
            <PopoverTrigger asChild>
              <div className="flex items-center cursor-pointer">
                <div>{filter}</div>
                <UIIcon name="arrow-down" size={18} />
              </div>
            </PopoverTrigger>
            <PopoverContent className="px-0 py-1 m-4 mt-0 text-sm cursor-pointer z-[250] max-w-[150px]">
              <RadioList<string>
                onChange={(val) => {
                  setFilter(val as NotificationsFilter);
                  setOpenSelect(false);
                }}
                options={NOTIFICATION_FILTERS_OPTIONS}
                selected={filter}
                setPaywallModalOpen={() => {
                  // empty
                }}
              />
            </PopoverContent>
          </Popover>

          <div
            className="flex items-center gap-1 cursor-pointer"
            onClick={markAllNotificationsAsRead}
          >
            <div>Mark all as read</div>
            <div>
              <UIIcon className="mb-[2px]" name="check-circle" size={14} />
            </div>
          </div>
          <UIIconButton
            name="cross"
            onClick={closeNotificationsSidebar}
            size={20}
          />
        </div>
      </div>
      {filteredItems?.map((item) => (
        <NotificationsCard item={item} key={item.id} />
      ))}
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%]">
        {filteredItems?.length === 0 && (
          <div className="p-4 text-center text-gray-50">
            <UIIcon name="bell" size={32} />
            <div className="mt-4">
              {filter === NotificationsFilter.Read &&
                'You have no read notifications.'}
              {filter === NotificationsFilter.Unread &&
                "You have no unread notifications. You're all caught up!"}
              {filter === NotificationsFilter.All &&
                "You have no notifications. You'll see them here once you have some."}
            </div>
          </div>
        )}

        {!filteredItems && <Loading />}
      </div>
    </div>
  );
};
