import './QAPageView.scss';
import React, { FC, useEffect, useMemo } from 'react';
import { useIsWorkspaceSetupNeeded } from '../../hooks/subscriptionHooks';
import { useAppConnectionSummary } from '../../pages/onboarding/WorkspaceSetupPage/hooks/app-connection-summary';
import { setUserPhotoUrl } from '../../redux/meta/actions';
import { useDispatch } from '../../redux/store';
import { QAController, QAControllerProvider } from '../../scripts/QAController';
import { getUserPhoto } from '../../scripts/apis';
import { useToaster } from '../../scripts/hooks';
import { logError } from '../../scripts/utils';
import { NotificationsProvider } from '../notifications/NotificationsContext';
import { QAFilesProvider } from './files/QAFilesContext';
import { QATextInputBoxStatesProvider } from './textInputBox/QATextInputBoxStates';

export const QAWrapper: FC = ({ children }) => {
  const qaController = useMemo(() => new QAController(), []);
  const toaster = useToaster();
  const { instantAppConnectedCount } = useAppConnectionSummary();
  const dispatch = useDispatch();
  const isWorkspaceSetupNeeded = useIsWorkspaceSetupNeeded();

  const hasConnectedAnyInstantApp = useMemo(
    () => instantAppConnectedCount > 0,
    [instantAppConnectedCount]
  );

  useEffect(() => {
    getUserPhoto()
      .then((response) => {
        dispatch(setUserPhotoUrl(response.photoUrl));
      })
      .catch(logError);
  }, [dispatch]);

  useEffect(() => {
    if (isWorkspaceSetupNeeded) return;

    qaController.fetchHistoryMessages(true).catch((error) => {
      toaster.failure(
        'Failed to fetch Dash AI messages, please try again later'
      );

      logError(error);
    });

    return () => {
      qaController.destruct();
    };
  }, [
    isWorkspaceSetupNeeded,
    qaController,
    toaster,
    hasConnectedAnyInstantApp,
  ]);

  return (
    <QAControllerProvider value={qaController}>
      <NotificationsProvider>
        <QAFilesProvider>
          <QATextInputBoxStatesProvider>
            {children}
          </QATextInputBoxStatesProvider>
        </QAFilesProvider>
      </NotificationsProvider>
    </QAControllerProvider>
  );
};
