import classNames from 'classnames';
import './SourcesFilterSwitch.scss';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { trackEvent } from '../../extra/sharedMethods';
import { Bot } from '../../models/Bots';
import { UploadedFile } from '../../models/File';
import { UserApp } from '../../models/User';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useFlag, useUserSafe } from '../../scripts/hooks';
import { UploadStatus } from '../../scripts/hooks/files';
import { ConnectedApps } from '../../scripts/hooks/sortedInstantApps';
import { Answer } from '../../scripts/models/answers';
import {
  checkAiOnlyModeFromSources,
  checkWebModeFromSources,
} from '../../scripts/sources/common';
import { isSquareImg } from '../../scripts/utils';
import { Modal } from '../controls/ui/Modal/Modal';
import { UIButton } from '../controls/ui/UIButton/UIButton';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { UITextbox } from '../controls/ui/UITextbox/UITextbox';
import { UITooltip } from '../controls/ui/UIToolTip/UIToolTip';
import { useQAFiles } from '../jit-qa/files/QAFilesContext';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../shadcn/lib/components/popover';
import { Tabs, TabsList, TabsTrigger } from '../shadcn/lib/components/tabs';
import { AttachFile } from './AttachFile/AttachFile';
import { BotKnowledgeDisplay } from './BotKnowledgeDisplay/BotKnowledgeDisplay';
import { KnowledgeSelect } from './KnowledgeSelect/KnowledgeSelect';

type TSourcesFilterTabNames = 'aiOnly' | 'files' | 'knowledge' | 'web';

interface ISourcesFilterSwitchProps {
  isBaseLLM: boolean;
  skillFilters: ConnectedApps[];
  fileFilters: UploadedFile[];
  answerFilters: Answer[];
  setIsBaseLLM: React.Dispatch<React.SetStateAction<boolean>>;
  setSkillFilters: React.Dispatch<React.SetStateAction<ConnectedApps[]>>;
  setFileFilters: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
  setAnswerFilters: React.Dispatch<React.SetStateAction<Answer[]>>;
  setUseContextualFilesOnly: React.Dispatch<React.SetStateAction<boolean>>;
  allSkills: ConnectedApps[];
  allSkillsUserApps: UserApp[];
  allFiles: UploadedFile[];
  allAnswers: Answer[];
  bot?: Bot;
  botsEnabledApps?: ConnectedApps[];
  botsEnabledUserApps?: UserApp[];
  error?: ReactNode;
  useContextualFilesOnly: boolean;
  showAttachFilesOption?: boolean;
  hasContextualFiles?: boolean;
  showConfigureAppSettings?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const SourcesFilterSwitch: FC<ISourcesFilterSwitchProps> = ({
  isBaseLLM,
  skillFilters,
  fileFilters,
  answerFilters,
  setIsBaseLLM,
  setSkillFilters,
  setFileFilters,
  setAnswerFilters,
  allSkills,
  allSkillsUserApps,
  allFiles,
  allAnswers,
  bot,
  botsEnabledApps,
  botsEnabledUserApps,
  error,
  useContextualFilesOnly = false,
  setUseContextualFilesOnly,
  showAttachFilesOption = false,
  hasContextualFiles = false,
  showConfigureAppSettings = false,
}) => {
  const {
    orgByOrgId: {
      preferences: { logo },
    },
  } = useUserSafe();

  const [tab, setTab] = useState<TSourcesFilterTabNames>(
    getTabValue(skillFilters, isBaseLLM, useContextualFilesOnly, bot)
  );

  const [popoverOpen, setPopoverOpen] = useState(false);

  const [isSquareLogo, setIsSquareLogo] = useState<boolean>(false);

  const [showConfigureAppSettingsModal, setShowConfigureAppSettingsModal] =
    useState<ConnectedApps | null>(null);

  const conversationFilesEnabled = useFlag('conversationFilesEnabled');
  const { uploadFiles, uploadedFiles, uploadStatus } = useQAFiles();

  const handleTabChange = useCallback((value: string) => {
    setTab(value as TSourcesFilterTabNames);
  }, []);

  const setSourceFiltersToKnowledge = useCallback(() => {
    if (
      skillFilters.some((app) => app.appName === 'web') ||
      isBaseLLM ||
      useContextualFilesOnly
    ) {
      setIsBaseLLM(false);
      setSkillFilters(allSkills);
      setFileFilters(allFiles);
      setAnswerFilters(allAnswers);
    }

    setUseContextualFilesOnly(false);

    trackEvent(AnalyticsEvent.QAAppsFilterButtonClicked, {
      isBaseLLM,
      searchFilters: [...skillFilters, ...fileFilters, ...answerFilters],
    });
  }, [
    skillFilters,
    useContextualFilesOnly,
    isBaseLLM,
    fileFilters,
    answerFilters,
    setIsBaseLLM,
    setSkillFilters,
    allSkills,
    setFileFilters,
    allFiles,
    setAnswerFilters,
    allAnswers,
    setUseContextualFilesOnly,
  ]);

  const setSourceFiltersToWeb = useCallback(() => {
    setUseContextualFilesOnly(false);
    setIsBaseLLM(false);
    setSkillFilters([{ appName: 'web', displayName: 'Web' }]);
    setFileFilters([]);
    setAnswerFilters([]);

    trackEvent(AnalyticsEvent.QAWebFilterButtonClicked);
  }, [
    setAnswerFilters,
    setFileFilters,
    setIsBaseLLM,
    setSkillFilters,
    setUseContextualFilesOnly,
  ]);

  const setSourceFiltersToAiOnly = useCallback(() => {
    setUseContextualFilesOnly(false);

    setIsBaseLLM(true);
    setSkillFilters([]);
    setFileFilters([]);
    setAnswerFilters([]);

    trackEvent(AnalyticsEvent.QAAiOnlyFilterButtonClicked);
  }, [
    setAnswerFilters,
    setFileFilters,
    setIsBaseLLM,
    setSkillFilters,
    setUseContextualFilesOnly,
  ]);

  const setSourceFiltersToConversationFilesOnly = useCallback(() => {
    setIsBaseLLM(false);
    setSkillFilters([]);
    setFileFilters([]);
    setAnswerFilters([]);
    setUseContextualFilesOnly(true);

    trackEvent(AnalyticsEvent.QAContextualFilesButtonClicked);
  }, [
    setAnswerFilters,
    setFileFilters,
    setIsBaseLLM,
    setSkillFilters,
    setUseContextualFilesOnly,
  ]);

  useEffect(() => {
    let isMounted = true;

    const checkIfSquareLogo = async () => {
      if (isMounted && logo) {
        const res = await isSquareImg(logo);
        setIsSquareLogo(res);
      } else {
        setIsSquareLogo(false);
      }
    };

    checkIfSquareLogo();

    return () => {
      isMounted = false;
    };
  }, [logo]);

  useEffect(() => {
    setTab(getTabValue(skillFilters, isBaseLLM, useContextualFilesOnly, bot));
  }, [skillFilters, isBaseLLM, bot, useContextualFilesOnly]);

  const isTabDisabled = (tabToCheck: TSourcesFilterTabNames) => {
    if (tabToCheck !== 'files' && uploadedFiles?.length) return true;
    if (uploadStatus === UploadStatus.TRANSIT) return true;

    return !!bot && tab !== tabToCheck;
  };

  useEffect(() => {
    if (uploadedFiles?.length && tab !== 'files' && conversationFilesEnabled) {
      setSourceFiltersToConversationFilesOnly();
    }
  }, [
    conversationFilesEnabled,
    setSourceFiltersToConversationFilesOnly,
    tab,
    uploadedFiles,
  ]);

  useEffect(() => {
    if (uploadStatus === UploadStatus.TRANSIT) {
      setSourceFiltersToConversationFilesOnly();
    }
  }, [uploadStatus, setSourceFiltersToConversationFilesOnly]);

  const isAttachFilesOptionEnabled =
    showAttachFilesOption && conversationFilesEnabled;

  return (
    <>
      <Tabs onValueChange={handleTabChange} value={tab}>
        <TabsList
          className={classNames('grid w-fit', {
            'border-mahogany-10': !!error,
            'grid-cols-3': !isAttachFilesOptionEnabled,
            'grid-cols-4': isAttachFilesOptionEnabled,
          })}
        >
          <TabsTrigger
            className="relative"
            disabled={isTabDisabled('knowledge')}
            onMouseDown={setSourceFiltersToKnowledge}
            value="knowledge"
          >
            {isSquareLogo ? (
              <UITooltip title="Search Apps, Files, and Answers">
                <div className="rounded flex">
                  <img alt="favicon" height={16} src={logo} width={16} />
                </div>
              </UITooltip>
            ) : (
              <UIIcon
                name="app"
                stroke
                tooltip="Search Apps, Files, and Answers"
              />
            )}
            <div
              className={`ml-1 sm:block hidden text-cloud-40 ${
                tab === 'knowledge' ? '!text-black' : ''
              }`}
            >
              Apps
            </div>
            <Popover onOpenChange={setPopoverOpen} open={popoverOpen}>
              <PopoverTrigger asChild>
                <div className="leading-[0]">
                  {(allSkills.length > 0 ||
                    allFiles.length > 0 ||
                    allAnswers.length > 0 ||
                    bot) && (
                    <UITooltip title="Select Apps, Files or Answers to search">
                      <UIIcon
                        className="selectChevron hover:pt-1"
                        name="arrow-down"
                      />
                    </UITooltip>
                  )}
                </div>
              </PopoverTrigger>
              <PopoverContent className="p-0 m-4 text-sm max-w-[270px] cursor-pointer z-[250]">
                <div>
                  {bot && botsEnabledApps && botsEnabledUserApps ? (
                    <BotKnowledgeDisplay
                      allAnswers={allAnswers}
                      allFiles={allFiles}
                      bot={bot}
                      botsEnabledApps={botsEnabledApps}
                      botsEnabledUserApps={botsEnabledUserApps}
                    />
                  ) : (
                    <KnowledgeSelect
                      allAnswers={allAnswers}
                      allFiles={allFiles}
                      allSkills={allSkills}
                      allSkillsUserApps={allSkillsUserApps}
                      answerFilters={answerFilters}
                      configureAppSettingsCallback={(app) => {
                        setPopoverOpen(false);
                        setShowConfigureAppSettingsModal(app);
                      }}
                      fileFilters={fileFilters}
                      setAnswerFilters={setAnswerFilters}
                      setFileFilters={setFileFilters}
                      setSkillFilters={setSkillFilters}
                      showConfigureAppSettings={showConfigureAppSettings}
                      skillFilters={skillFilters}
                    />
                  )}
                </div>
              </PopoverContent>
            </Popover>
          </TabsTrigger>

          {isAttachFilesOptionEnabled && (
            <>
              {!hasContextualFiles && (
                <AttachFile
                  disabled={isTabDisabled('files')}
                  onFilesAttached={(files) => {
                    uploadFiles(files);
                  }}
                >
                  <TabsTrigger
                    disabled={isTabDisabled('files')}
                    onMouseDown={setSourceFiltersToConversationFilesOnly}
                    style={{ width: '100%' }}
                    value="files"
                  >
                    <UIIcon name="attachment" tooltip="Attach files" />
                    <div
                      className={`ml-1 sm:block hidden text-cloud-40 ${
                        tab === 'files' ? '!text-black' : ''
                      }`}
                    >
                      Attach
                    </div>
                  </TabsTrigger>
                </AttachFile>
              )}
              {hasContextualFiles && (
                <TabsTrigger
                  disabled={isTabDisabled('files')}
                  onMouseDown={setSourceFiltersToConversationFilesOnly}
                  style={{ width: '100%' }}
                  value="files"
                >
                  <UIIcon name="attachment" />
                  <div
                    className={`ml-1 sm:block hidden text-cloud-40 ${
                      tab === 'files' ? '!text-black' : ''
                    }`}
                  >
                    Files
                  </div>

                  <AttachFile
                    disabled={isTabDisabled('files')}
                    onFilesAttached={(files) => {
                      uploadFiles(files);
                      setSourceFiltersToConversationFilesOnly();
                    }}
                  >
                    <UIIcon
                      className="ml-0 sm:ml-1 mb-[1px]"
                      name="plus"
                      size={14}
                      tooltip="Attach more files"
                    />
                  </AttachFile>
                </TabsTrigger>
              )}
            </>
          )}

          <TabsTrigger
            disabled={isTabDisabled('web')}
            onMouseDown={setSourceFiltersToWeb}
            value="web"
          >
            <UIIcon name="web" size={16} tooltip="Search Web" type="apps" />
            <div
              className={`ml-1 sm:block hidden text-cloud-40 ${
                tab === 'web' ? '!text-black' : ''
              }`}
            >
              Web
            </div>
          </TabsTrigger>

          <TabsTrigger
            disabled={isTabDisabled('aiOnly')}
            onMouseDown={setSourceFiltersToAiOnly}
            value="aiOnly"
          >
            <UIIcon name="ai-sparkle" tooltip="Ask AI without search" />
            <div
              className={`ml-1 sm:block hidden text-cloud-40 ${
                tab === 'aiOnly' ? '!text-black' : ''
              }`}
            >
              AI Only
            </div>
          </TabsTrigger>
        </TabsList>
      </Tabs>
      {/* Only show this if the error is an actual node */}
      {error && error !== true && (
        <div className="text-sm mt-[5px] text-mahogany-20">{error}</div>
      )}

      {showConfigureAppSettingsModal && (
        <ConfigureAppSettingsModal
          app={showConfigureAppSettingsModal}
          closeModal={() => {
            setShowConfigureAppSettingsModal(null);
            setPopoverOpen(true);
          }}
          setSkillFilters={setSkillFilters}
          skillFilters={skillFilters}
        />
      )}
    </>
  );
};

const getTabValue = (
  skillFilters: ConnectedApps[],
  isBaseLLM: boolean,
  useContextualFilesOnly: boolean,
  bot?: Bot
) => {
  if (bot) {
    if ((bot.sources.contextual_files ?? []).length > 0) return 'files';
    if (checkAiOnlyModeFromSources(bot.sources)) return 'aiOnly';
    if (checkWebModeFromSources(bot.sources)) return 'web';
    return 'knowledge';
  }

  if (useContextualFilesOnly) return 'files';
  if (isBaseLLM) return 'aiOnly';
  if (skillFilters.some((app) => app.appName === 'web')) return 'web';
  return 'knowledge';
};

const ConfigureAppSettingsModal: FC<{
  app: ConnectedApps;
  skillFilters: ConnectedApps[];
  closeModal: () => void;
  setSkillFilters: React.Dispatch<React.SetStateAction<ConnectedApps[]>>;
}> = ({ app, skillFilters, closeModal, setSkillFilters }) => {
  const [files, setFiles] = useState('');
  const [folders, setFolders] = useState('');
  const [drives, setDrives] = useState('');
  const [channels, setChannels] = useState('');

  const handleSave = useCallback(() => {
    setSkillFilters((prev) =>
      prev.map((item) =>
        item.id === app.id
          ? {
              ...item,
              appSpecificFilters: {
                ...(files && {
                  files: files.split(',').map((file) => file.trim()),
                }),
                ...(folders && {
                  folders: folders.split(',').map((folder) => folder.trim()),
                }),
                ...(drives && {
                  drives: drives.split(',').map((drive) => drive.trim()),
                }),
                ...(channels && {
                  channels: channels
                    .split(',')
                    .map((channel) => channel.trim()),
                }),
              },
            }
          : item
      )
    );

    closeModal();
  }, [closeModal, app.id, setSkillFilters, files, folders, drives, channels]);

  useEffect(() => {
    const matchedApp = skillFilters.find((item) => item.id === app.id);
    if (matchedApp) {
      setFiles(matchedApp.appSpecificFilters?.files?.join(',') ?? '');
      setFolders(matchedApp.appSpecificFilters?.folders?.join(',') ?? '');
      setDrives(matchedApp.appSpecificFilters?.drives?.join(',') ?? '');
      setChannels(matchedApp.appSpecificFilters?.channels?.join(',') ?? '');
    }
  }, [app.id, skillFilters]);

  return (
    <Modal hasMaxZIndex modalClasses="max-w-xl" onClose={closeModal}>
      {(() => {
        if (app.appName === 'gdrive') {
          return (
            <>
              <div className="flex gap-2 items-center">
                <UIIcon name={app.appName} type="apps" />
                <div className="text-lg font-semibold">
                  Specify sources in Google Drive
                </div>
              </div>
              <div className="mt-6 flex flex-col gap-2">
                <div className="font-bold">Document URLs</div>
                <UITextbox
                  onChange={setFiles}
                  placeholder="Enter comma separated Document URLs"
                  value={files}
                />
              </div>
              <div className="mt-2 flex flex-col gap-2">
                <div className="font-bold">Folder URLs</div>
                <UITextbox
                  onChange={setFolders}
                  placeholder="Enter comma separated Folder URLs"
                  value={folders}
                />
              </div>
              <div className="mt-2 flex flex-col gap-2">
                <div className="font-bold">Drive URLs</div>
                <UITextbox
                  onChange={setDrives}
                  placeholder="Enter comma separated Drive URLs"
                  value={drives}
                />
              </div>
            </>
          );
        }

        if (app.appName === 'slack') {
          return (
            <>
              <div className="flex gap-2 items-center">
                <UIIcon name={app.appName} type="apps" />
                <div className="text-lg font-semibold">
                  Specify sources in Slack
                </div>
              </div>
              <div className="mt-6 flex flex-col gap-2">
                <div className="font-bold">Channel Names</div>
                <UITextbox
                  onChange={setChannels}
                  placeholder="Enter comma separated channel names"
                  value={channels}
                />
              </div>
            </>
          );
        }

        return null;
      })()}
      <div className="flex mt-4 gap-4 justify-between">
        <UIButton onClick={closeModal} type="secondary">
          Cancel
        </UIButton>
        <UIButton
          disabled={
            files === '' && folders === '' && drives === '' && channels === ''
          }
          onClick={handleSave}
        >
          Save
        </UIButton>
      </div>
    </Modal>
  );
};
