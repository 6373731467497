import React, { FC, ReactNode, useEffect } from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { trackEvent } from '../../extra/sharedMethods';
import { Bot } from '../../models/Bots';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';

interface QAMentionsInputProps {
  autofocus: boolean;
  showPopupFromBottom?: boolean;
  inputRef: React.RefObject<HTMLTextAreaElement>;
  onKeyDown: (event: React.KeyboardEvent) => void;
  handleTextInputChange: (newValue: string) => void;
  dynamicPlaceholder: string;
  searchQuery: string;
  mentions?: string;
  setMentions: (mentions?: string) => void;
  bots?: Bot[];
}

interface MentionData {
  id: string;
  display: string;
}

export const QAMentionsInput: FC<QAMentionsInputProps> = ({
  autofocus,
  showPopupFromBottom = false,
  inputRef,
  onKeyDown,
  handleTextInputChange,
  dynamicPlaceholder,
  searchQuery,
  mentions,
  setMentions,
  bots,
}) => {
  const mentionsData = bots?.map((bot) => ({
    id: bot.id,
    display: bot.bot_name,
  })) as MentionData[];

  return (
    <MentionsInput
      allowSuggestionsAboveCursor
      autoFocus={autofocus}
      className="bg-transparent block text-base flex-1 border-0 rounded-lg resize-none outline-none w-full break-words"
      // eslint-disable-next-line react/no-unstable-nested-components
      customSuggestionsContainer={(children: ReactNode): ReactNode => (
        <div
          className={`bg-white border border-solid border-gray-30 rounded-md w-72 max-h-[300px] overflow-y-auto !cursor absolute left-2 ${
            showPopupFromBottom ? 'top-0' : 'bottom-0'
          }`}
        >
          {children}
        </div>
      )}
      forceSuggestionsAboveCursor
      inputRef={inputRef}
      onChange={(_, newValue) => {
        handleTextInputChange(newValue);
        if (mentions && !newValue.includes(mentions)) {
          setMentions(undefined);
        }
      }}
      onKeyDown={onKeyDown}
      placeholder={dynamicPlaceholder}
      style={{
        control: {
          border: 'none',
          outline: 'none',
        },
        highlighter: {
          overflow: 'hidden',
          maxHeight: '250px',
        },
        input: {
          margin: 0,
          border: 'none',
          outline: 'none',
          overflow: 'auto',
          maxHeight: '250px',
          scrollbarWidth: 'none',
        },
        suggestions: {
          zIndex: 1000,
        },
      }}
      tabIndex={1}
      value={searchQuery}
    >
      <Mention
        appendSpaceOnAdd
        className="mentions__suggestions"
        data={mentions ? [] : mentionsData}
        displayTransform={(_, display) => ` @${display} `}
        markup="@[__display__](__id__)"
        onAdd={(id) => {
          setMentions(String(id));
          trackEvent(AnalyticsEvent.MentionedBot, { bot_id: id });
        }}
        renderSuggestion={(
          entry,
          _search,
          highlightedDisplay,
          _index,
          focused
        ) => {
          return (
            <RenderSuggestion
              bots={bots}
              entry={entry}
              focused={focused}
              highlightedDisplay={highlightedDisplay}
            />
          );
        }}
        style={{
          backgroundColor: '#C9FFFF',
          borderRadius: '6px',
          marginLeft: '2px',
          paddingBottom: '4px',
        }}
        trigger="@"
      />
    </MentionsInput>
  );
};

interface RenderSuggestionProps {
  entry: SuggestionDataItem;
  highlightedDisplay: ReactNode;
  focused: boolean;
  bots?: Bot[];
}

const RenderSuggestion: React.FC<RenderSuggestionProps> = ({
  entry,
  highlightedDisplay,
  focused,
  bots,
}) => {
  const ref = React.createRef<HTMLDivElement>();

  const bot = bots?.find((d) => d.id === entry.id);

  useEffect(() => {
    if (focused && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
    }
  }, [focused, ref]);

  return (
    <div
      className={`flex items-center gap-2 p-2 cursor-pointer ${
        focused ? 'bg-cloud-10' : 'bg-white'
      }`}
      key={entry.id}
      ref={ref}
    >
      {bot?.icon ?? '🤖'} {highlightedDisplay}
    </div>
  );
};
